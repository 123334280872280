var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
import { DateTime } from "luxon";
import auth from "@/firebase";
import CustomReloadedIncidence from "@/models/customReloadedIncidence";
import { trainings } from "@/api/trainings";
import { bulk } from "@/api/bulk";
import { permissions } from "@/api/permissions";
import { plans } from "@/api/plans";
import { internalProcess } from "@/api/internalProcess";
import { zentric } from "@/api/zentric";
import { delegatePermissions } from "@/api/delegatePermissions";
import { upsell } from "@/api/upsell";
import { documentEditor } from "@/api/documentEditor";
import digitalSign from "@/api/digitalSign";
import daysOff from "@/api/daysOff";
import {
  setSessionTokens,
  setWATokens,
  getSessionTokens,
  getWATokens,
  setLastRefreshTime,
  setRefreshInProgress
} from "@/utils/session/handleSessionToken";
import { logger, PROCESS_NAMES } from "@/utils/logger";
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true;
export const aborts = {
  calendar: null,
  directoryStats: null
};
function logout() {
  return __async(this, null, function* () {
    axios.post("/api/v1/users/logout/", { refresh: getSessionTokens().refresh }).catch((error) => logger.error("Logout Worky API request failed:", error));
    auth.signOut().then(() => logger.info("Logout from firebase successful")).catch((err) => logger.error(`Logout firebase error: ${err}`));
    clearLocalStorageByKeys();
    localStorage.clear();
    updateAxiosToken();
  });
}
const clearLocalStorageByKeys = (keysToRemove = ["token", "watoken", "refresh", "warefresh", "vuex", "redirectPath"]) => {
  keysToRemove.forEach((key) => {
    localStorage.removeItem(key);
  });
};
function updateAxiosToken() {
  const { token = null } = getSessionTokens();
  axios.defaults.headers.common.Authorization = token ? `JWT ${token}` : null;
}
function getNewToken() {
  return __async(this, null, function* () {
    const {
      refresh: refreshWorkyAdminToken = null,
      token: tokenWorkyAdminToken = null
    } = getWATokens() || {};
    const {
      refresh: refreshSessionToken = null
    } = getSessionTokens() || {};
    const axiosInstance = axios.create();
    try {
      const { data = {} } = (yield axios.post("/refresh/", { refresh: refreshSessionToken })) || {};
      if (!(data == null ? void 0 : data.access) || !(data == null ? void 0 : data.refresh)) {
        return Promise.reject(new Error("No access or refresh token received"));
      }
      setSessionTokens(data.access, data.refresh);
      updateAxiosToken();
      if (tokenWorkyAdminToken) {
        axiosInstance.defaults.headers.Authorization = `JWT ${tokenWorkyAdminToken}`;
        const { data: dataWorkyAdmin = {} } = (yield axiosInstance.post("/refresh/", { refresh: refreshWorkyAdminToken })) || {};
        setWATokens(dataWorkyAdmin.access, dataWorkyAdmin.refresh);
      }
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  });
}
axios.interceptors.request.use(
  (config) => {
    const { token = null } = getSessionTokens();
    if (token) {
      config.headers.Authorization = `JWT ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
axios.interceptors.response.use(
  (response) => response,
  (error) => __async(void 0, null, function* () {
    return yield interceptorResponseOnError(error);
  })
);
let hasAlreadyAttemptedRefresh = false;
function interceptorResponseOnError(error) {
  return __async(this, null, function* () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m;
    const isAuthError = ((_a = error == null ? void 0 : error.response) == null ? void 0 : _a.status) === 401;
    const isLoginRequest = ((_d = (_c = (_b = error == null ? void 0 : error.config) == null ? void 0 : _b.url) == null ? void 0 : _c.includes) == null ? void 0 : _d.call(_c, "/token/")) || ((_g = (_f = (_e = error == null ? void 0 : error.config) == null ? void 0 : _e.url) == null ? void 0 : _f.includes) == null ? void 0 : _g.call(_f, "verify"));
    const isPayrollRequest = (_j = (_i = (_h = error == null ? void 0 : error.config) == null ? void 0 : _h.baseURL) == null ? void 0 : _i.includes) == null ? void 0 : _j.call(_i, "zentric.mx");
    const isRefreshTokenRequest = (_m = (_l = (_k = error == null ? void 0 : error.config) == null ? void 0 : _k.url) == null ? void 0 : _l.includes) == null ? void 0 : _m.call(_l, "/refresh/");
    if (isLoginRequest || !isAuthError || isPayrollRequest) {
      return Promise.reject(error);
    }
    if (hasAlreadyAttemptedRefresh) {
      return Promise.reject(error);
    }
    hasAlreadyAttemptedRefresh = true;
    setRefreshInProgress(true);
    try {
      const data = yield getNewToken();
      if (!(data == null ? void 0 : data.access) || !(data == null ? void 0 : data.refresh)) {
        logger.error(PROCESS_NAMES.AUTH, "Error on interceptor, no access or refresh token received at ", new Date().toISOString());
        setRefreshInProgress(false);
        return Promise.reject(error);
      }
      setLastRefreshTime();
      setRefreshInProgress(false);
      hasAlreadyAttemptedRefresh = false;
      if (isRefreshTokenRequest) {
        return data;
      }
      error.config.headers.Authorization = `JWT ${data.access}`;
      return axios.request(error.config);
    } catch (refreshError) {
      logger.error(PROCESS_NAMES.AUTH, "Error on interceptor during token refresh", refreshError);
      setRefreshInProgress(false);
      return Promise.reject(error);
    }
  });
}
function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; ) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === `${name}=`) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
      i += 1;
    }
  }
  return cookieValue;
}
function backdoor(data) {
  return axios.post("/b4ckd00r/", data);
}
function switchCompany(companyId) {
  return axios.put(`/api/v1/organization_company/${companyId}/switch/`).then(({ data }) => data);
}
const download = {
  tokenFile() {
    return axios.get("/api/v1/token_file/");
  },
  vacationsExcel() {
    return axios.get(
      "/api/v2/vacations/company/excel/",
      { responseType: "arraybuffer" }
    );
  },
  sendFileByEmail({ id, email }) {
    return axios.post(`/api/v2/employees/${id}/download_archive/`, { email });
  },
  downloadAttachment(url) {
    return axios.get(`${url}`, { responseType: "arraybuffer" });
  }
};
const approvalFlows = {
  getTotals({
    startDate,
    endDate,
    status
  }) {
    let startDateSearch = "";
    let endDateSearch = "";
    if (startDate) {
      startDateSearch = `&start_date=${startDate}`;
    }
    if (endDate) {
      endDateSearch = `&end_date=${endDate}`;
    }
    return axios.get(`api/v2/incidences/monetary_totals/?status=${status}${startDateSearch}${endDateSearch}`);
  },
  getPendingFilesZip(id) {
    return axios.get(`api/v2/incidences/${id}/zip_files/`, { responseType: "arraybuffer" });
  },
  getMonetaryExcel(razones, areas2) {
    let razonFilter = "";
    let areaFilter = "";
    if (razones !== "") {
      razonFilter = `&razon_social_in=${razones}`;
    }
    if (areas2 !== "") {
      areaFilter = `&area_in=${areas2}`;
    }
    return axios.get(`/api/v2/monetary_excel/?${razonFilter}${areaFilter}`, { responseType: "arraybuffer" });
  },
  validateExcel(file2) {
    const formData = new FormData();
    formData.append("file", file2);
    return axios.post(
      `/api/v2/monetary_excel/validate/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
  },
  uploadMonetaryExcel(file2) {
    const formData = new FormData();
    formData.append("file", file2);
    return axios.post(
      `/api/v2/monetary_excel/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
  },
  getApprovals() {
    return axios.get("/api/v2/review_policies/");
  },
  getFlowInfo(flowId) {
    return axios.get(`/api/v2/review_policies/${flowId}/`);
  },
  createApproval({ data }) {
    return axios.post("/api/v2/review_policies/", data);
  },
  editApproval({ data, id }) {
    return axios.put(`/api/v2/review_policies/${id}/`, data);
  },
  deleteFlow({ id }) {
    return axios.delete(`/api/v2/review_policies/${id}`);
  },
  getRequestsCards(employeeId) {
    return axios.get(`/api/v2/employees/${employeeId}/available_requests/`);
  },
  getRequestsCardsNewVersion(employeeId, is_request, is_boss) {
    let request = "";
    let boss = "";
    if (is_request)
      request = "?is_request=True";
    if (is_boss)
      boss = "&is_boss=True";
    return axios.get(`/api/v2/employees/${employeeId}/available_request_v2_front/${request}${boss}`).then(({ data }) => data);
  },
  getMyUpcomingRequests(date) {
    return axios.get(`api/v2/incidences/?start_date_gte=${date}`);
  },
  getMyPreviousRequests(date) {
    return axios.get(`api/v2/incidences/?start_date_lt=${date}`);
  },
  getPendingSelects(idCompany) {
    return axios.get(`api/v2/companies/${idCompany}/incidences/?time_clock=false&status=PEN`);
  },
  /* eslint-disable-next-line */
  addApproverComment({ id, reviewers_comment }) {
    return axios.patch(`/api/v2/review_flow/reviewers/${id}/`, { reviewers_comment });
  },
  addApproverFiles({ id, files }) {
    axios.patch(`/api/v2/review_flow/reviewers/${id}/files/`, files);
  },
  getPendingRequests(_0) {
    return __async(this, arguments, function* ({
      areas: areas2,
      legacyTypes,
      customTypes,
      startDate,
      endDate,
      idCompany,
      searchTerm,
      page
    }) {
      const incidenceObj = new CustomReloadedIncidence();
      const result = yield incidenceObj.getCompanyPendingRequests({
        idCompany,
        areas: areas2,
        legacyTypes,
        customTypes,
        startDate,
        endDate,
        searchTerm,
        page
      });
      return result;
    });
  },
  getTeamPendingRequests({
    areas: areas2,
    legacyTypes,
    customTypes,
    startDate,
    endDate,
    searchTerm,
    page
  }) {
    let areasSearch = null;
    let incidenceSearch = null;
    let startDateSearch = null;
    let endDateSearch = null;
    let searchT = null;
    let pagination = "";
    const searches = [];
    const incidenceTypes = legacyTypes.concat(customTypes);
    if (page !== null) {
      pagination = `&paginate=true&page=${page}`;
    }
    if (searchTerm) {
      searchT = `&owner_full_name=${searchTerm}`;
      searches.push(searchT);
    }
    if (areas2.length > 0) {
      areasSearch = `&area_in=${areas2}`;
      searches.push(areasSearch);
    }
    if (startDate) {
      startDateSearch = `&start_date_gte=${startDate}`;
      searches.push(startDateSearch);
    }
    if (endDate) {
      if (!startDate) {
        const mockTodayStart = DateTime.local().toISODate();
        endDateSearch = `&start_date_gte=${mockTodayStart}&end_date_lte=${endDate}`;
      } else {
        endDateSearch = `&end_date_lte=${endDate}`;
      }
      searches.push(endDateSearch);
    }
    if (incidenceTypes && incidenceTypes.length > 0) {
      incidenceSearch = incidenceTypes.reduce((stringCompleted, typeEl, idx) => idx === 0 ? `&incidence_or_template=${typeEl},` : `${stringCompleted}${typeEl},`, "").slice(0, -1);
      searches.push(incidenceSearch);
    }
    const notNullSearchesUrl = searches.filter((search) => search).reduce((url, search, idx) => idx === 0 ? `api/v2/team/incidences/?time_clock=false&status=PEN${search}${pagination}` : `${url}${search}`, "");
    if (notNullSearchesUrl === "") {
      return axios.get(`api/v2/team/incidences/?time_clock=false&status=PEN${pagination}`);
    }
    return axios.get(`${notNullSearchesUrl}`);
  },
  getReviewerPendingRequests({
    areas: areas2,
    legacyTypes,
    customTypes,
    startDate,
    endDate
  }) {
    let areasSearch = null;
    let legacySearch = null;
    let customSearch = null;
    let startDateSearch = null;
    let endDateSearch = null;
    if (legacyTypes && legacyTypes.length > 0) {
      if (areas2) {
        areasSearch = areas2.reduce((stringCompleted, areaEl, index) => index === 0 ? `area_in=${areaEl},` : `${stringCompleted}${areaEl},`, "");
      }
      if (startDate) {
        startDateSearch = `start_date_gte=${startDate}`;
      }
      if (endDate) {
        if (!startDate) {
          const mockTodayStart = DateTime.local().toISODate();
          endDateSearch = `start_date_gte=${mockTodayStart}&end_date_lte=${endDate}`;
        } else {
          endDateSearch = `end_date_lte=${endDate}`;
        }
      }
      legacySearch = legacyTypes.reduce((stringCompleted, typeEl, idx) => idx === 0 ? `incidence_type_in=${typeEl},` : `${stringCompleted}${typeEl},`, "");
      const searches2 = [
        areasSearch,
        startDateSearch,
        endDateSearch,
        legacySearch
      ];
      const notNullSearchesUrl2 = searches2.filter((search) => search).reduce((url, search, idx) => idx === 0 ? `api/v2/reviewers/incidences/?time_clock=false&status=PEN&${search}&` : `${url}${search}&`, "");
      return axios.get(`${notNullSearchesUrl2}`);
    }
    if (customTypes && customTypes.length > 0) {
      if (areas2) {
        areasSearch = areas2.reduce((stringCompleted, areaEl, index) => index === 0 ? `area_in=${areaEl},` : `${stringCompleted}${areaEl},`, "");
      }
      if (startDate) {
        startDateSearch = `start_date_gte=${startDate}`;
      }
      if (endDate) {
        if (!startDate) {
          const mockTodayStart = DateTime.local().toISODate();
          endDateSearch = `start_date_gte=${mockTodayStart}&end_date_lte=${endDate}`;
        } else {
          endDateSearch = `end_date_lte=${endDate}`;
        }
      }
      customSearch = customTypes.reduce((stringCompleted, typeEl, idx) => idx === 0 ? `template_in=${typeEl},` : `${stringCompleted}${typeEl},`, "");
      const searches2 = [
        areasSearch,
        startDateSearch,
        endDateSearch,
        customSearch
      ];
      const notNullSearchesUrl2 = searches2.filter((search) => search).reduce((url, search, idx) => idx === 0 ? `api/v2/reviewers/incidences/?time_clock=false&status=PEN&${search}&` : `${url}${search}&`, "");
      return axios.get(`${notNullSearchesUrl2}`);
    }
    if (areas2) {
      areasSearch = areas2.reduce((stringCompleted, areaEl, index) => index === 0 ? `area_in=${areaEl},` : `${stringCompleted}${areaEl},`, "");
      areasSearch = areasSearch.slice(0, -1);
    }
    if (startDate) {
      startDateSearch = `start_date_gte=${startDate}`;
    }
    if (endDate) {
      if (!startDate) {
        const mockTodayStart = DateTime.local().toISODate();
        endDateSearch = `start_date_gte=${mockTodayStart}&end_date_lte=${endDate}`;
      } else {
        endDateSearch = `end_date_lte=${endDate}`;
      }
    }
    const searches = [
      areasSearch,
      startDateSearch,
      endDateSearch
    ];
    const notNullSearchesUrl = searches.filter((search) => search).reduce((url, search, idx) => idx === 0 ? `api/v2/reviewers/incidences/?time_clock=false&status=PEN&${search}&` : `${url}${search}&`, "");
    if (notNullSearchesUrl === "") {
      return axios.get(`api/v2/reviewers/incidences/?time_clock=false&status=PEN`);
    }
    return axios.get(`${notNullSearchesUrl}`);
  },
  getApproverIncidences({
    idCompany,
    status,
    legacyTypes,
    customTypes,
    startDate,
    endDate,
    timeClock: timeClock2,
    searchTerm,
    page
  }) {
    let startDateSearch = null;
    let incidenceSearch = null;
    let endDateSearch = null;
    let timeClockSearch = null;
    let searchT = null;
    let pagination = "";
    const searches = [];
    const incidenceTypes = legacyTypes.concat(customTypes);
    if (timeClock2 !== null) {
      timeClockSearch = `time_clock=${timeClock2}`;
      searches.push(timeClockSearch);
    }
    if (page !== null) {
      pagination = `&paginate=true&page=${page}`;
    }
    if (searchTerm) {
      searchT = `&owner_full_name=${searchTerm}`;
      searches.push(searchT);
    }
    if (startDate) {
      startDateSearch = `start_date_lte=${endDate}`;
      searches.push(startDateSearch);
    }
    if (endDate) {
      if (!startDate) {
        const mockTodayStart = DateTime.local().toISODate();
        endDateSearch = `end_date_gte=${mockTodayStart}&start_date_lte=${endDate}`;
      } else {
        endDateSearch = `end_date_gte=${startDate}`;
      }
      searches.push(endDateSearch);
    }
    if (incidenceTypes && incidenceTypes.length > 0) {
      incidenceSearch = incidenceTypes.reduce((stringCompleted, typeEl, idx) => idx === 0 ? `&incidence_or_template=${typeEl},` : `${stringCompleted}${typeEl},`, "").slice(0, -1);
      searches.push(incidenceSearch);
    }
    const api2 = "api/v2/reviewers/incidences/resolved/";
    const finalUrl = searches.filter((search) => search).reduce((url, search, idx) => idx === 0 ? `${api2}?${search}${pagination}&` : `${url}${search}&`, "");
    return axios.get(`${finalUrl}`);
  },
  getIncidences({
    idCompany,
    status,
    legacyTypes,
    customTypes,
    startDate,
    endDate,
    timeClock: timeClock2,
    searchTerm,
    page
  }, getType) {
    let statusSearch = null;
    let startDateSearch = null;
    let incidenceSearch = null;
    let endDateSearch = null;
    let timeClockSearch = null;
    let searchT = null;
    let pagination = "";
    const searches = [];
    const incidenceTypes = legacyTypes.concat(customTypes);
    if (timeClock2 !== null) {
      timeClockSearch = `time_clock=${timeClock2}`;
      searches.push(timeClockSearch);
    }
    if (page !== null) {
      pagination = `&paginate=true&page=${page}`;
    }
    if (searchTerm) {
      searchT = `&owner_full_name=${searchTerm}`;
      searches.push(searchT);
    }
    if (startDate) {
      startDateSearch = `start_date_lte=${endDate}`;
      searches.push(startDateSearch);
    }
    if (endDate) {
      if (!startDate) {
        const mockTodayStart = DateTime.local().toISODate();
        endDateSearch = `end_date_gte=${mockTodayStart}&start_date_lte=${endDate}`;
      } else {
        endDateSearch = `end_date_gte=${startDate}`;
      }
      searches.push(endDateSearch);
    }
    if (status) {
      statusSearch = status.reduce((stringCompleted, statusEl, index) => index === 0 ? `status_in=${statusEl},` : `${stringCompleted}${statusEl},`, "");
    } else {
      statusSearch = `status_in=APR,REJ,CNX`;
    }
    searches.push(statusSearch);
    if (incidenceTypes && incidenceTypes.length > 0) {
      incidenceSearch = incidenceTypes.reduce((stringCompleted, typeEl, idx) => idx === 0 ? `&incidence_or_template=${typeEl},` : `${stringCompleted}${typeEl},`, "").slice(0, -1);
      searches.push(incidenceSearch);
    }
    const api2 = getType === "team" ? "api/v2/team/incidences/" : getType === "company" ? `api/v2/companies/${idCompany}/incidences/` : "api/v2/reviewers/incidences/resolved/";
    const notNullSearchesUrl = searches.filter((search) => search).reduce((url, search, idx) => idx === 0 ? `${api2}?${search}${pagination}&` : `${url}${search}&`, "");
    if (notNullSearchesUrl === "") {
      return axios.get(`${api2}?time_clock=false&status=PEN${pagination}`);
    }
    return axios.get(`${notNullSearchesUrl}`);
  },
  isEmployeeApprover(employeeId) {
    return axios.get(`/api/v2/review_policies/employee/${employeeId}/`);
  },
  getMinPendingIncidences(filters, id) {
    return __async(this, null, function* () {
      const query = Object.keys(filters).map((key) => `${key}=${filters[key]}`);
      query.push("use_min=true&status=PEN&time_clock=false");
      const { data } = yield axios.get(`api/v2/companies/${id}/incidences/?${query.join("&")}`);
      return data;
    });
  },
  getMinTeamPendingIncidences(filters) {
    return __async(this, null, function* () {
      const query = Object.keys(filters).map((key) => `${key}=${filters[key]}`);
      query.push("use_min=true&status=PEN&time_clock=false");
      const { data } = yield axios.get(`api/v2/team/incidences/?${query.join("&")}`);
      return data;
    });
  },
  getMinApproverPendingIncidences(filters) {
    return __async(this, null, function* () {
      const query = Object.keys(filters).map((key) => `${key}=${filters[key]}`);
      query.push("use_min=true&status=PEN&time_clock=false");
      const { data } = yield axios.get(`api/v2/reviewers/incidences/?${query.join("&")}`);
      return data;
    });
  }
};
const tags = {
  addTags(data) {
    return axios.post(`/api/v2/employees/tags/assign_tags/`, data);
  },
  getTags() {
    return axios.get(`/api/v2/employees/tags/`);
  },
  getTagsDescription(description) {
    return axios.get(`/api/v2/employees/tags/?category=${description}`);
  },
  getEmployeeTags(tagId) {
    return axios.get(`/api/v2/employees/tags/${tagId}`);
  },
  deleteTag(tagId) {
    return axios.delete(`/api/v2/employees/tags/${tagId}/`);
  },
  editTag(tagId, name) {
    return axios.patch(`/api/v2/employees/tags/${tagId}/`, name);
  },
  getAssignedTags(tagId) {
    return axios.get(`/api/v2/employees/tags/${tagId}/employees/`);
  },
  removeAssignedTags(employees2, tagName) {
    return axios.post(`/api/v2/employees/tags/remove_tags/`, { employees: employees2, tag: tagName });
  }
};
const evaluations = {
  /* Métodos para plantillas */
  getTemplates() {
    return axios.get("api/v1/evaluations/templates/");
  },
  getEmployeeEvaluations(idEmployee) {
    return axios.get(`/api/v1/evaluations/company/?evaluator=${idEmployee}`);
  },
  getTemplateById(id) {
    return axios.get(`api/v1/evaluations/templates/${id}`);
  },
  createTemplate(data) {
    return axios.post("/api/v1/evaluations/templates/", data);
  },
  deleteTemplate(id) {
    return axios.delete(`/api/v1/evaluations/templates/${id}/`);
  },
  getTemplateDetail(id) {
    const resp = axios.get(`/api/v1/evaluations/templates/${id}/`);
    return resp;
  },
  updateTemplate(data, id) {
    return axios.patch(`/api/v1/evaluations/templates/${id}/`, data);
  },
  getTemplatesSelect() {
    return axios.get(`/api/v1/evaluations/templates/as_select/`);
  },
  /* Métodos para evaluaciones de compañía */
  getCompanyEvaluations(status) {
    return __async(this, null, function* () {
      const list = yield axios.get(`/api/v1/evaluations/company/${status}`);
      return list.data;
    });
  },
  addEvaluator(evaluationId, data) {
    return __async(this, null, function* () {
      return axios.post(`/api/v1/evaluations/company/${evaluationId}/add_evaluator/`, data);
    });
  },
  removeEvaluator(evaluationId, data) {
    return __async(this, null, function* () {
      return axios.post(`/api/v1/evaluations/company/${evaluationId}/remove_evaluation/`, data);
    });
  },
  downloadStatusEvaluation(evaluationId) {
    return axios.get(
      `api/v1/evaluations/company/${evaluationId}/download_status/`,
      { responseType: "arraybuffer" }
    );
  },
  /* getCompanyArchivedEvaluations ({ status }) {
    return axios.get(`/api/v1/evaluations/company/?status=${status}`)
  }, */
  getArchivedEvaluationsResults() {
    return axios.get(`/api/v1/evaluations/company/results/`);
  },
  getEvaluationGeneralResults(evaluationId) {
    return axios.get(`/api/v1/evaluations/company/results/${evaluationId}/`);
  },
  getEvaluationsCompany() {
    const data = axios.get(`/api/v1/evaluations/company/`);
    return data;
  },
  getEvaluationIndividualResults(evaluationId) {
    return axios.get(`/api/v1/evaluations/individual_responses/?evaluation=${evaluationId}`);
  },
  getEvaluationSpecificResults(evaluationId, type, id) {
    return __async(this, null, function* () {
      const { data } = yield axios.get(`/api/v1/evaluations/company/results/${evaluationId}/?${type}=${id}`);
      return data;
    });
  },
  downloadEvaluationGeneralResults(evaluationId) {
    return __async(this, null, function* () {
      const resp = yield axios.get(
        `/api/v1/evaluations/company/results/${evaluationId}/download_report/`,
        { responseType: "arraybuffer" }
      );
      return resp;
    });
  },
  downloadEvaluationSpecificResults(evaluationId, type, id) {
    return __async(this, null, function* () {
      const resp = yield axios.get(
        `/api/v1/evaluations/company/results/${evaluationId}/download_report/?${type}=${id}`,
        { responseType: "arraybuffer" }
      );
      return resp;
    });
  },
  getArchivedEvaluationResult(evaluationId, evaluatedId) {
    return axios.get(`/api/v1/evaluations/company/results/${evaluationId}/?evaluated=${evaluatedId}`);
  },
  /* Métodos para evaluaciones de equipo */
  getTeamArchivedEvaluations({ status }) {
    return axios.get(`/api/v1/evaluations/team/?status=${status}`);
  },
  getTeamArchivedEvaluationsResults() {
    return axios.get(`/api/v1/evaluations/team/results/`);
  },
  getTeamEvaluations(status) {
    return __async(this, null, function* () {
      const list = yield axios.get(`/api/v1/evaluations/team/${status}`);
      return list.data;
    });
  },
  /* Métodos para Mis evaluaciones */
  // Obtener evaluaciones pendientes EM
  getPendingEvaluations() {
    return __async(this, null, function* () {
      const list = yield axios.get("api/v1/evaluations/questionaries/?sent=false&status=0");
      return list.data;
    });
  },
  getQuestionary(questionaryId) {
    return axios.get(`/api/v1/evaluations/questionaries/${questionaryId}/`);
  },
  getQuestionaryEvaluated(employeeId) {
    return axios.get(`/api/v1/evaluations/questionaries/evaluated/?employee_id=${employeeId}`);
  },
  getSentEvaluations() {
    return __async(this, null, function* () {
      const list = yield axios.get("/api/v1/evaluations/questionaries/?sent=true");
      return list.data;
    });
  },
  getEvaluationsResults() {
    return __async(this, null, function* () {
      const list = yield axios.get("/api/v1/evaluations/results/");
      return list.data;
    });
  },
  getEvaluationResultDetail(evaluationId) {
    return __async(this, null, function* () {
      const data = yield axios.get(`/api/v1/evaluations/results/${evaluationId}/`);
      return data.data;
    });
  },
  createNewEvaluation(data) {
    return axios.post("/api/v1/evaluations/company/", data);
  },
  createNewTeamEvaluation(data) {
    return axios.post("/api/v1/evaluations/team/", data);
  },
  cancelEvaluation(evaluationId) {
    return axios.post(`/api/v1/evaluations/company/${evaluationId}/cancel/`);
  },
  closeEvaluation(evaluationId) {
    return axios.post(`/api/v1/evaluations/company/${evaluationId}/close/`);
  },
  extendEvaluation(evaluationId, date) {
    return axios.patch(`/api/v1/evaluations/company/${evaluationId}/`, { limit: date });
  },
  publishEvaluation(evaluationId) {
    return axios.post(`/api/v1/evaluations/company/${evaluationId}/publish/`);
  },
  cancelTeamEvaluation(evaluationId) {
    return axios.post(`/api/v1/evaluations/company/${evaluationId}/cancel/`);
  },
  closeTeamEvaluation(evaluationId) {
    return axios.post(`/api/v1/evaluations/company/${evaluationId}/close/`);
  },
  publishTeamEvaluation(evaluationId) {
    return axios.post(`/api/v1/evaluations/company/${evaluationId}/publish/`);
  },
  answerEvaluation({ data, questionaryId }) {
    return axios.put(`/api/v1/evaluations/questionaries/${questionaryId}/`, data);
  },
  // Evaluaciones
  getEvaluationDetail(id) {
    return __async(this, null, function* () {
      const list = yield axios.get(`api/v1/evaluations/company/${id}/`);
      return list;
    });
  },
  getEvaluationStatus(id) {
    return __async(this, null, function* () {
      const list = yield axios.get(`api/v1/evaluations/company/${id}/detail_status`);
      return list.data;
    });
  },
  sendReminder(id) {
    return axios.post(`/api/v1/evaluations/company/${id}/reminder/`);
  },
  sendIndividualReminder(id, data) {
    return axios.post(`/api/v1/evaluations/company/${id}/reminder/`, data);
  },
  sendIndividualResults(evaluationId, data) {
    return axios.post(`/api/v1/evaluations/company/${evaluationId}/notify_results/`, data);
  },
  sendNotification(evaluationId) {
    return axios.post(`/api/v1/evaluations/company/${evaluationId}/notify/`);
  },
  sendResults(evaluationId) {
    return axios.post(`/api/v1/evaluations/company/${evaluationId}/notify_results/`);
  },
  canDownload(evaluationId) {
    return axios.get(`/api/v1/evaluations/company/results/${evaluationId}/can_download/`);
  },
  sendResultByEmail(evaluationId, data, query = "") {
    return axios.post(`/api/v1/evaluations/company/results/${evaluationId}/send_report/${query}`, data);
  }
};
const downloadFiltered = {
  tokenFile(startDate, endDate, data) {
    return axios.get(`/api/v2/incidences/download/?dates=${startDate},${endDate}`, data);
  },
  sendFileByEmail(id, email) {
    return axios.post(`/api/v2/employees/${id}/download_archive/`, email);
  }
};
const vacations = {
  employeesBalance() {
    return axios.get("/api/v2/vacations/company/");
  },
  getEmployeeVacationsDetails(employeeId) {
    return axios.get(`/api/v2/vacations/employee/${employeeId}/vacation_balance/`);
  },
  getEmployeePTODetails(employeeId) {
    return axios.get(`/api/v2/vacations/employee/${employeeId}/pto_balance/`);
  },
  getRecentsMovements(employeeId, type, page) {
    return axios.get(`/api/v2/employees/incidences/${employeeId}/?incidence_type=${type}&offset=${page}&paginate=true/`);
  }
};
const vacationsV3 = {
  addFilesToEditRequestComment(idComment, files) {
    return axios.post(`/api/v1/comments/${idComment}/files/`, files);
  },
  approveEditRequest(id) {
    return axios.post(`/api/v1/time_off/editing_request/${id}/approve/`).then(({ data }) => data);
  },
  rejectEditRequest(id) {
    return axios.post(`/api/v1/time_off/editing_request/${id}/reject/`).then(({ data }) => data);
  },
  getEditRequest(id) {
    return axios.get(`api/v1/time_off/editing_request/${id}/`).then(({ data }) => data);
  },
  addCommentEditRequest(payload) {
    return axios.post("api/v1/comments/", payload).then(({ data }) => data);
  },
  deleteRequest(requestId, comment) {
    let payload = "";
    if (comment) {
      payload = {
        comment
      };
    }
    return axios.post(`api/v2/requests/${requestId}/cancel/`, payload);
  },
  adjustBalances(data) {
    return axios.post("api/v1/time_off/adjustments/", data);
  },
  cancelIncidences(incidenceId, comment) {
    let payload = "";
    if (comment) {
      payload = {
        comment
      };
    }
    return axios.post(`api/v2/incidences/${incidenceId}/cancel/`, payload);
  },
  cancelRequestIncidences(incidenceId, comment) {
    let payload = "";
    if (comment) {
      payload = {
        comment
      };
    }
    return axios.post(`api/v2/requests/${incidenceId}/cancel/`, payload);
  },
  employeeBalances(employeeId, options = {}) {
    const query = Object.keys(options).map((option) => `${option}=${options[option]}`).join("&");
    return axios.get(`api/v1/time_off/employee_balances/${employeeId}/?${query}`);
  },
  employeesBalances({ page }) {
    const pg = page ? `?${page}` : "";
    const url = `api/v1/time_off/balances/${pg}`;
    return axios.get(url);
  },
  simulate(data) {
    return __async(this, null, function* () {
      const response = yield axios.post(`api/v1/time_off/forecasts/simulate/`, data);
      return response.data;
    });
  },
  simulationDownload(dataPolicies) {
    return axios.post(`api/v1/time_off/forecasts/simulate_excel/`, dataPolicies, { responseType: "arraybuffer" }).then(({ data }) => data);
  },
  simulationSend(dataPolicies) {
    return axios.post(`api/v1/time_off/forecasts/simulate_excel/?send_mail=true`, dataPolicies);
  },
  simulationSendEmail(dataPolicies) {
    return axios.post(`/api/v1/time_off/forecasts/actual_excel/`, dataPolicies);
  },
  searchEmployeesBalances(employeeName) {
    return axios.get(`api/v1/time_off/balances/?query=${employeeName}`);
  },
  employeeRequest(data) {
    return __async(this, null, function* () {
      const response = yield axios.post("api/v1/time_off/requests/", data);
      return response.data;
    });
  },
  // policies
  associatePolicy(employeeId, data) {
    return axios.post(`api/v1/time_off/policies/${employeeId}/associate/`, data);
  },
  changePolicy(employeeId, data) {
    return axios.post(`api/v1/time_off/policies/${employeeId}/change/`, data);
  },
  getEmployeesWithPolicies() {
    return axios.get("api/v1/time_off/policies/employees/");
  },
  getPolicySummary(employeeId, policyType) {
    return axios.get(`api/v1/time_off/policies/${employeeId}/?incidence_type=${policyType}`);
  },
  massiveChangePolicy(data) {
    return axios.put("api/v1/time_off/policies/massive_change/", data);
  },
  massiveChangePolicyUpdated(data) {
    return axios.post("api/v1/time_off/policies/employees/massive_change/", data);
  },
  singleChangePolicy(data, employee) {
    return axios.post(`/api/v1/time_off/policies/employees/${employee}/change/`, data);
  },
  getExcel() {
    return axios.get(
      "api/v1/time_off/excel/",
      { responseType: "arraybuffer" }
    );
  },
  readExcel(file2) {
    const formData = new FormData();
    formData.append("file", file2);
    return axios.post(
      `api/v1/time_off/excel/validate/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
  },
  uploadExcel(file2) {
    const formData = new FormData();
    formData.append("file", file2);
    return axios.post(
      `api/v1/time_off/excel/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
  },
  update({ id, data }) {
    return axios.put(`api/v1/time_off/requests/${id}/`, data);
  },
  updateIncident({ id, data }) {
    return axios.put(`api/v1/time_off/incidences/${id}/`, data);
  },
  policiesVerify() {
    return axios.get(`api/v1/time_off/policies/employees/verify/`);
  },
  sendDownloadVacationBalance(sendTo, query) {
    return axios.post(
      `/api/v1/time_off/report/vacations/?${query}`,
      { email: sendTo }
    );
  },
  downloadVacationBalanceFile(query) {
    return axios.get(
      `/api/v1/time_off/report/vacations/?${query}`,
      { responseType: "arraybuffer" }
    );
  },
  sendDownloadPTOBalance(sendTo, query) {
    return axios.post(
      `/api/v1/time_off/report/pto/?${query}`,
      { email: sendTo }
    );
  },
  downloadPTOBalanceFile(query) {
    return axios.get(
      `/api/v1/time_off/report/pto/?${query}`,
      { responseType: "arraybuffer" }
    );
  },
  getExistingIncidences(companyId, data) {
    return axios.post(`api/v2/companies/${companyId}/get_existing_incidences/?ordering=owner__first_name`, data);
  },
  getAssignStatus() {
    return axios.get("/api/v1/time_off/policies/employees/check_status_policy_task/").then(({ data }) => data);
  }
};
const benefits = {
  getBenefit(id) {
    return axios.get(`api/v2/benefits/${id}/`).then(({ data }) => data);
  },
  getBenefits(options = { status: 1 }) {
    return axios.get("api/v2/benefits/", { params: options }).then(({ data }) => data);
  },
  getWorkyBenefits() {
    return axios.get(`api/v2/worky_benefits/`).then(({ data }) => data);
  },
  createBenefit(benefit) {
    return axios.post("/api/v2/benefits/", benefit).then(({ data }) => data);
  },
  updateBenefit(benefit) {
    return axios.patch(`/api/v2/benefits/${benefit.id}/`, benefit).then(({ data }) => data);
  },
  uploadFile(benefit, file2, field = "banner") {
    const formData = new FormData();
    formData.append(field, file2);
    return axios.patch(`/api/v2/benefits/${benefit.id}/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(({ data }) => data);
  },
  deleteBenefit(benefit) {
    return axios.delete(`/api/v2/benefits/${benefit.id}/`).then(({ data }) => data);
  },
  myBenefits() {
    return axios.get("api/v2/benefits/employee_benefits/").then(({ data }) => data);
  }
};
const dashboard = {
  getEmployeesSelect() {
    return axios.get(`api/v2/employees/public/as_select/?laid_off=false`);
  },
  getActiveEmployeesSelect() {
    return axios.get(`/api/v2/employees/company/?laid_off=false&invited=true`);
  },
  getCompanyBenefits(companyId) {
    return axios.get(`api/v2/companies/${companyId}/benefits/`);
  },
  getTimeOffPolicies() {
    return axios.get("/api/v1/dashboard/time_off/");
  },
  getRecruitments() {
    return axios.get("/api/v1/dashboard/recruitments/");
  },
  getBirthdates(companyId) {
    return axios.get(`api/v1/dashboard/${companyId}/birthdates/?congratulations=True/`);
  },
  getMyBenefits() {
    return axios.get(`api/v2/benefits/employee_benefits/`);
  },
  getAnniversaries(companyId) {
    return axios.get(`api/v1/dashboard/${companyId}/anniversaries/?congratulations=True/`);
  },
  getHolidays(companyId) {
    return axios.get(`api/v1/dashboard/${companyId}/holidays/`);
  },
  getDirectory() {
    return axios.get("api/v1/dashboard/directory/");
  },
  getVacations() {
    return axios.get("api/v1/dashboard/vacations/");
  },
  getCalendar(companyId) {
    return axios.get(`api/v1/dashboard/${companyId}/calendar/`);
  },
  getIncidences() {
    return axios.get("api/v1/dashboard/incidences/");
  },
  getEmployees() {
    return __async(this, null, function* () {
      const list = yield axios.get("api/v1/dashboard/employees/");
      return list;
    });
  }
};
const timeOff = {
  getCompanyPolicies() {
    return axios.get(`api/v2/vacations/company/policies/`);
  },
  createPolicy(data) {
    return axios.post(`api/v2/vacations/company/policies/`, data);
  },
  updatePolicy({ id, data }) {
    return axios.put(`api/v2/vacations/company/policies/${id}/`, data);
  },
  deletePolicy({ id }) {
    return axios.delete(`api/v2/vacations/company/policies/${id}/`);
  }
};
const directory = {
  getEmployeeDocuments(id) {
    return axios.get(`/api/v2/employees/${id}/documents/`);
  },
  createEmployee(data) {
    return axios.post(`api/v2/employees/`, data);
  },
  createEmployeeFromWA(data) {
    return axios.post(`api/v2/employees/`, data);
  },
  downloadDirectory(companyId) {
    return axios.get(`/api/v2/companies/${companyId}/employees_list/`, { responseType: "arraybuffer" });
  },
  getDirectory(query = {}) {
    const queryText = Object.keys(query).map((q) => `${q}=${query[q]}`).join("&");
    return axios.get(`/api/v2/employees/directory/?laid_off=false&invited=true${queryText ? `&${queryText}` : ""}`);
  },
  getDirectoryFilters(query = {}) {
    const queryText = Object.keys(query).map((q) => `${q}=${query[q]}`).join("&");
    return axios.get(`/api/v2/employees/directory/?${queryText ? `${queryText}` : ""}`);
  },
  getMinDirectory(filters) {
    const query = Object.keys(filters).map((key) => `${key}=${filters[key]}`);
    query.push("use_min=true");
    return axios.get(`/api/v2/employees/directory/?${query.join("&")}`).then(({ data }) => data);
  },
  getMinDirectoryCreateIncidences(filters) {
    const query = Object.keys(filters).map((key) => `${key}=${filters[key]}`);
    return axios.get(`/api/v2/employees/directory/?${query.join("&")}`).then(({ data }) => data);
  },
  getDirectoryEm() {
    return axios.get("/api/v2/employees/company/");
  },
  getDirectoryFiltered(filters) {
    return axios.get(`/api/v2/employees/company/?${filters}`);
  },
  getProfile(employeeId) {
    return axios.get(`/api/v2/employees/${employeeId}/`);
  },
  downloadEmployeeArchive(employeeId) {
    return axios.get(`/api/v2/employees/${employeeId}/download_archive/`, { responseType: "arraybuffer" });
  },
  getEmployees(query = {}) {
    const queryText = Object.keys(query).map((q) => `${q}=${query[q]}`).join("&");
    return axios.get(`api/v2/employees/company/?laid_off=false&invited=true${queryText ? `&${queryText}` : ""}`);
  },
  getEmployeesHRAFiltered() {
    return axios.get(`api/v2/employees/company/?laid_off=false&invited=true&user__role=HRA`);
  },
  getBossEmployees(boss) {
    return axios.get(`api/v2/employees/company/?laid_off=false&invited=true&boss=${boss}`);
  },
  laidOffEmployee({
    employeeId,
    /* eslint-disable camelcase */
    work_end_date,
    laid_off_reason,
    laid_off_comments
  }) {
    return axios.post(`/api/v2/employees/${employeeId}/laid_off/`, {
      work_end_date,
      laid_off_reason,
      laid_off_comments
    });
  },
  replaceReviewer({ policyId, data }) {
    return axios.post(`/api/v2/review_policies/${policyId}/replace_reviewer/`, data);
  },
  sendDownloadFormerEmployee(sendTo, query) {
    return axios.post(
      `/api/v2/employees/excolabs_report/${query}`,
      { email: sendTo }
    );
  },
  downloadFormerEmployeeFile(query) {
    return axios.get(
      `/api/v2/employees/excolabs_report/${query}`,
      { responseType: "arraybuffer" }
    );
  },
  getDocumentsLaidOff(employeeId) {
    return axios.get(`api/v2/employees/${employeeId}/documents/`).then(({ data }) => data);
  },
  getDirectoryStats() {
    if (aborts.directoryStats) {
      aborts.directoryStats.abort();
    }
    aborts.directoryStats = new AbortController();
    const options = {
      signal: aborts.directoryStats.signal
    };
    return axios.get("api/v2/employees/complete_profiles/", options).then(({ data }) => data);
  },
  getEmployeesFilters(filters) {
    const query = Object.keys(filters).map((key) => `${key}=${filters[key]}`);
    return axios.get(`api/v2/employees/directory/?${query.join("&")}`);
  },
  getEmployee(employeeId) {
    return axios.get(`/api/v2/employees/${employeeId}`).then(({ data }) => data);
  },
  downloadReportBackground(data, filters = {}) {
    let query = "";
    if (filters) {
      const str = Object.keys(filters).map((key) => {
        let q;
        if (Array.isArray(filters[key])) {
          q = filters[key].length > 0 ? `${key}=${filters[key]}` : "";
        } else {
          q = `${key}=${filters[key]}`;
        }
        return q;
      });
      const arr = str.filter((it) => it !== "");
      query = arr.length === 0 ? "" : arr.length === 1 ? `?${arr[0]}` : `?${arr.join("&")}`;
    }
    return axios.post(`/api/v1/reports/download/${query}`, data, { responseType: "arraybuffer" });
  },
  sendReportBackground(data, filters) {
    let query = "";
    if (filters) {
      const str = Object.keys(filters).map((key) => {
        let q;
        if (Array.isArray(filters[key])) {
          q = filters[key].length > 0 ? `${key}=${filters[key]}` : "";
        } else {
          q = `${key}=${filters[key]}`;
        }
        return q;
      });
      const arr = str.filter((it) => it !== "");
      query = arr.length === 0 ? "" : arr.length === 1 ? `?${arr[0]}` : `?${arr.join("&")}`;
    }
    return axios.post(`/api/v1/reports/download/${query}`, data);
  },
  getDirectoryEmployees(params = []) {
    return axios.get(`/api/v2/employees/directory/`, { params });
  }
};
const calendar = {
  getMyCalendarIncidences({ startDate, endDate, status }) {
    const baseUrl = `/api/v2/calendar/employee/?`;
    const statusFilter = status ? `status=${status}` : null;
    const startDateFilter = startDate ? `end_date_gte=${startDate}` : null;
    const endDateFilter = endDate ? `start_date_lte=${endDate}` : null;
    const filters = [statusFilter, startDateFilter, endDateFilter].filter((filter) => filter).join("&");
    return axios.get(`${baseUrl}${filters}`);
  },
  getCalendarIncidences({ startDate, endDate, status }) {
    const baseUrl = `/api/v2/calendar/company/?`;
    const statusFilter = status ? `status=${status}` : null;
    const startDateFilter = startDate ? `end_date_gte=${startDate}` : null;
    const endDateFilter = endDate ? `start_date_lte=${endDate}` : null;
    const filters = [statusFilter, startDateFilter, endDateFilter].filter((filter) => filter).join("&");
    return axios.get(`${baseUrl}${filters}`);
  },
  getTeamCalendarIncidences({ startDate, endDate, status }) {
    const baseUrl = `/api/v2/calendar/team/?`;
    const statusFilter = status ? `status=${status}` : null;
    const startDateFilter = startDate ? `end_date_gte=${startDate}` : null;
    const endDateFilter = endDate ? `start_date_lte=${endDate}` : null;
    const filters = [statusFilter, startDateFilter, endDateFilter].filter((filter) => filter).join("&");
    return axios.get(`${baseUrl}${filters}`);
  },
  sendDownloadCalendar(sendTo, query) {
    return axios.post(
      `/api/v1/calendar/report/?${query}`,
      { email: sendTo }
    );
  },
  downloadCalendarFile(query) {
    return axios.get(
      `/api/v1/calendar/report/?${query}`,
      { responseType: "arraybuffer" }
    );
  },
  getCalendar(filter) {
    if (aborts.calendar) {
      aborts.calendar.abort();
    }
    aborts.calendar = new AbortController();
    const options = {
      signal: aborts.calendar.signal
    };
    const query = Object.keys(filter).map((key) => `${key}=${filter[key]}`).join("&");
    return axios.get(`/api/v1/calendar/?${query}`, options).then(({ data }) => data);
  },
  createEvent(event) {
    return axios.post("/api/v1/events/", event).then(({ data }) => data);
  },
  setAttendanceToEvent(eventId, attendance) {
    return axios.put(`/api/v1/events/occurrences/${eventId}/mark_attendance/`, { attendance });
  },
  updateItemTodoList({ id, completed }) {
    return axios.put(`/api/v1/tasks/todo_list/${id}/`, { completed }).then(({ data }) => data);
  },
  removeEvent({ id }, query) {
    const queryString = Object.keys(query).filter((q) => query[q] !== null).map((q) => `${q}=${query[q]}`).join("&");
    return axios.delete(`/api/v1/events/occurrences/${id}/?${queryString}`).then(({ data }) => data);
  },
  getCustomCatalog() {
    return axios.get("/api/v2/incidences/custom_catalog/").then(({ data }) => data);
  },
  updateEvent(_e, rule) {
    var _f = _e, { id } = _f, model = __objRest(_f, ["id"]);
    return axios.put(`/api/v1/events/occurrences/${id}/`, __spreadValues(__spreadValues({}, model), rule ? { update_rule: rule } : {}));
  },
  removeParticipants({ id }, removePayload) {
    return axios.post(`/api/v1/events/occurrences/${id}/remove_attendees/`, removePayload);
  },
  addParticipants({ id }, addPayload) {
    return axios.post(`/api/v1/events/occurrences/${id}/add_attendees/`, addPayload);
  },
  getIncidencesCatalog() {
    return axios.get("/api/v2/incidences/catalog").then(({ data }) => data);
  }
};
const announcements = {
  downloadReport(announcement) {
    return axios.get(`/api/v1/announcements/${announcement}/download_status/`, { responseType: "arraybuffer" });
  },
  getPublishedAnnouncementsList(offset, limit = 3) {
    return axios.get(`/api/v1/announcements/?announcement_sent=true&limit=${limit}&offset=${offset}&paginate=true`);
  },
  getScheduledAnnouncementsList(offset, limit = 3) {
    return axios.get(`/api/v1/announcements/?announcement_sent=false&limit=${limit}&offset=${offset}&paginate=true`);
  },
  getEmployeesByArea(areaId) {
    return axios.get(`api/v2/areas/${areaId}/min_employees/`);
  },
  getEmployeesByLocation(locationId) {
    return axios.get(`/api/v2/locations/${locationId}/employees/`);
  },
  getEmployeesByBusinessUnit(businessId) {
    return axios.get(`/api/v2/business_units/${businessId}/employees`);
  },
  createAnnouncement(announcement) {
    return axios({
      method: "post",
      url: "/api/v1/announcements/",
      data: __spreadValues({ must_read: true }, announcement)
    });
  },
  uploadCover(announcementId, file2) {
    const bodyFormData = new FormData();
    bodyFormData.append("cover", file2);
    return axios({
      method: "patch",
      url: `/api/v1/announcements/${announcementId}/`,
      data: bodyFormData
    });
  },
  uploadAttachement(announcementId, file2) {
    const bodyFormData = new FormData();
    bodyFormData.append("attachment", file2);
    return axios({
      method: "patch",
      url: `/api/v1/announcements/${announcementId}/`,
      data: bodyFormData
    });
  },
  deleteAttachement(announcementId, fileId) {
    return axios.delete(`/api/v1/announcements/${announcementId}/attachments/${fileId}`);
  },
  updateAnnouncement(announcement) {
    return axios({
      method: "patch",
      url: `/api/v1/announcements/${announcement.id}/`,
      data: announcement
    });
  },
  deleteAnnouncement(announcementId) {
    return axios.delete(`/api/v1/announcements/${announcementId}`);
  },
  getAnnouncementAudience(announcementId) {
    return axios.get(`/api/v1/announcements/${announcementId}/audience/`);
  },
  markAnnouncementAsRead(announcementId) {
    return axios.post(`/api/v1/announcements/${announcementId}/mark_as_read/?platform=web`);
  },
  getAnnouncementInteractions(announcementId) {
    return axios.get(`/api/v1/announcements/${announcementId}/interactions/`);
  },
  getMyReactions(announcementId) {
    return axios.get(`api/v1/announcements/${announcementId}/my_reactions/`);
  },
  reactAnnouncement(announcementId, reactions) {
    return axios.post(`/api/v1/announcements/${announcementId}/react/`, reactions);
  },
  sendDownloadAnnouncement(sendTo, startDate, endDate) {
    return axios.post(
      `/api/v1/announcements/report/?created_after=${startDate}&created_before=${endDate}`,
      { email: sendTo }
    );
  },
  downloadAnnouncementFile(startDate, endDate) {
    return axios.get(
      `/api/v1/announcements/report/?created_after=${startDate}&created_before=${endDate}`,
      { responseType: "arraybuffer" }
    );
  }
};
const me = {
  getUser() {
    return axios.get("api/v1/me/");
  }
};
const employees = {
  parseXLSX(data) {
    return axios.put("/employees/parser/", data);
  },
  fileParseXLSX(data) {
    return axios.put("/employees/file_parser/", data);
  },
  updateProfile({ id, data }) {
    return axios.patch(`api/v2/employees/${id}/`, data);
  },
  updateBoss({ data }) {
    return axios.put(`/api/v2/employees/assign_boss/`, data);
  },
  updateArea(data) {
    return axios.put(`/api/v2/employees/assign_area/`, data);
  },
  profileHistory({ id, page }) {
    const pg = page ? `?${page}` : "";
    const url = `api/v2/employees/history/${id}/${pg}`;
    return axios.get(url);
  }
};
const profile = {
  getPublicProfileComplete(employeeId) {
    return axios.get(`api/v1/profile/public/${employeeId}/?invalidate_cache=true`);
  },
  getPrivateProfileComplete(employeeId) {
    return axios.get(`api/v1/profile/private/${employeeId}/?invalidate_cache=true`);
  },
  getIdFromUrl(employeeUrl) {
    return __async(this, null, function* () {
      const { data } = yield axios.get(`api/v1/profile/public/?url_username=${employeeUrl}`);
      return data.employee;
    });
  },
  getPublicProfile(employeeId) {
    return axios.get(`api/v1/profile/public/${employeeId}/basic_info/`);
  },
  setLaboralInfo(employeeId, data) {
    return axios.patch(`api/v2/employees/${employeeId}/`, data);
  },
  setWorkInfo(employeeId, data) {
    return axios.patch(`api/v1/profile/public/${employeeId}/basic_info/`, data);
  },
  getMyTeam(employeeId) {
    return axios.get(`api/v1/profile/public/${employeeId}/team/`);
  },
  getSubstitute(employeeId) {
    return axios.get(`api/v1/profile/public/${employeeId}/out_of_office/`);
  },
  notifyUserNameChange(employeeId) {
    return axios.post(`api/v1/profile/public/${employeeId}/notify_username_change/`);
  },
  setSubstitute(employeeId, data) {
    return axios.post(`api/v1/profile/public/${employeeId}/out_of_office/`, data);
  },
  getHobbies(employeeId) {
    return axios.get(`api/v1/profile/public/${employeeId}/hobbies/`);
  },
  setHobbies(employeeId, data) {
    return axios.post(`api/v1/profile/public/${employeeId}/hobbies/`, data);
  },
  getCustomResponse(id) {
    return axios.get(`api/v1/profile/public/${id}/custom_box/`);
  },
  setCustomResponse(id, data) {
    return axios.post(`api/v1/profile/public/${id}/custom_box/`, data);
  },
  getExpertise(employeeId) {
    return axios.get(`api/v1/profile/public/${employeeId}/experience/`);
  },
  setExpertise(employeeId, data) {
    return axios.post(`api/v1/profile/public/${employeeId}/experience/`, data);
  },
  getExpDocs(documentId) {
    return axios.get(`api/v2/employees/public_documents/${documentId}/`);
  },
  askFeedback(data) {
    return axios.post(`api/v1/profile/feedback/`, data);
  },
  // private profile
  getPayrollInfo(employeeId) {
    return axios.get(`api/v1/profile/private/${employeeId}/payroll_info/`);
  },
  setPayrollInfo(employeeId, data) {
    return axios.patch(`api/v1/profile/private/${employeeId}/payroll_info/`, data);
  },
  salaryRaise(data) {
    return axios.post(`api/v1/profile/salary_raise/`, data);
  },
  getPersonalInfo(employeeId) {
    return axios.get(`api/v1/profile/private/${employeeId}/general_info/`);
  },
  setPersonalInfo(employeeId, data) {
    return axios.patch(`api/v1/profile/private/${employeeId}/general_info/`, data);
  },
  setOnboardingInfo(employeeId, data) {
    return axios.patch(`api/v2/employees/${employeeId}/`, data);
  },
  getCustomInfo(employeeId) {
    return axios.get(`api/v1/profile/private/${employeeId}/customized_info/`);
  },
  getDocumentsInfo(employeeId) {
    return axios.get(`api/v1/profile/private/${employeeId}/documents_info/`);
  },
  getAnnivCongrats(employeeId) {
    return axios.get(`api/v1/profile/private/${employeeId}/anniversary_messages/`);
  },
  getBirthCongrats(employeeId) {
    return axios.get(`api/v1/profile/private/${employeeId}/birthday_messages/`);
  },
  setCongrats(data) {
    return axios.post("api/v1/dashboard/congratulate/", data);
  },
  getIncidencesHistory(employeeId, page) {
    const pg = page ? `?${page}` : "";
    const url = `api/v1/profile/private/${employeeId}/incidence_history/${pg}`;
    return axios.get(url);
  },
  createAddress(payload) {
    return axios.post("/api/v1/profile/addresses/", payload);
  },
  updateAddress(idAddress, payload) {
    return axios.put(`/api/v1/profile/addresses/${idAddress}/`, payload);
  },
  deleteAddress(idAddress) {
    return axios.delete(`/api/v1/profile/addresses/${idAddress}/`);
  }
};
function company(companyId) {
  return {
    parseBalanceXLSX(data) {
      return axios.post(`/api/v2/vacations/company/excel/`, data);
    },
    getEmployeesMinimumInfo() {
      return axios.get(`/api/v2/companies/${companyId}/min_employees/`);
    },
    getEmployeeRemoteDays() {
      return axios.get(`/api/v2/companies/${companyId}/employees_working_hours/`);
    },
    updateEmployeeRemoteDays(employee) {
      return axios.put(`/api/v2/companies/${companyId}/employees_working_hours/`, employee);
    },
    getCompanyHras() {
      return axios.get(`/api/v2/companies/${companyId}/hras/`);
    },
    getEmployees() {
      return axios.get(`/api/v2/companies/${companyId}/employees/`);
    },
    locations: {
      getLocations(minInfo) {
        return __async(this, null, function* () {
          const useMin = minInfo ? "?use_min=true" : "";
          return axios.get(`api/v2/locations/${useMin}`);
        });
      },
      updateLocation(office) {
        return __async(this, null, function* () {
          return axios.put(`/api/v2/locations/${office.id}/`, office);
        });
      },
      sendQRCode(officeId, data) {
        return __async(this, null, function* () {
          return axios.post(`/api/v1/time_clock/location/${officeId}/send_email/`, data);
        });
      },
      getLocationsFilter(filters) {
        return __async(this, null, function* () {
          let query = "";
          if (filters) {
            query = `?${Object.keys(filters).map((key) => `${key}=${filters[key]}`).join("&")}`;
          }
          return axios.get(`api/v2/locations/${query}`);
        });
      }
    },
    hraCheckList: {
      getCheckedItems(idCompany) {
        return __async(this, null, function* () {
          return axios.get(`api/v2/companies/${idCompany}/checklist/`);
        });
      },
      checkItem(idCompany, data) {
        return __async(this, null, function* () {
          return axios.put(`api/v2/companies/${idCompany}/checklist/`, data);
        });
      }
    },
    jobProfile: {
      create(jobProfile) {
        return __async(this, null, function* () {
          return axios.post(`api/v1/profile/template/`, jobProfile);
        });
      },
      getList() {
        return __async(this, null, function* () {
          const list = yield axios.get(`/api/v1/profile/templates_company/`);
          return list;
        });
      },
      get(jobProfileId) {
        return __async(this, null, function* () {
          return axios.get(`/api/v1/profile/template/${jobProfileId}`);
        });
      },
      employeeJobDetail(jobProfileId) {
        return __async(this, null, function* () {
          return axios.get(`/api/v1/profile/custom/${jobProfileId}`);
        });
      },
      editEmployeeDetail(jobProfile) {
        return __async(this, null, function* () {
          return axios.put(`/api/v1/profile/custom/${jobProfile.id}/`, jobProfile);
        });
      },
      assign(jobProfileId, data) {
        return __async(this, null, function* () {
          return axios.post(`api/v1/profile/template/${jobProfileId}/associate/`, data);
        });
      },
      unAssign(jobProfileId, data) {
        return __async(this, null, function* () {
          return axios.post(`api/v1/profile/custom/disassociate/`, data);
        });
      },
      duplicate(jobProfileId) {
        return __async(this, null, function* () {
          return axios.post(`api/v1/profile/template/${jobProfileId}/duplicate/`);
        });
      },
      delete(jobProfileId) {
        return __async(this, null, function* () {
          return axios.post(`api/v1/profile/template/${jobProfileId}/invalidate/`);
        });
      },
      update(jobProfile) {
        return __async(this, null, function* () {
          return axios.put(`/api/v1/profile/template/${jobProfile.id}/`, jobProfile);
        });
      }
    },
    getProfileSettings() {
      return axios.get(`api/v2/companies/${companyId}/profile_config/`);
    },
    getCustomSections() {
      return axios.get(`api/v2/custom_profile/`);
    },
    updateCustomSection(data) {
      return axios.put(`api/v2/custom_profile/${data.id}/`, data);
    },
    setCustomSection(data) {
      return axios.post(`api/v2/custom_profile/`, data);
    },
    setProfileSettings(data) {
      return axios.post(`api/v2/companies/${companyId}/profile_config/`, data);
    },
    getTeamSettings() {
      return axios.get(`api/v2/companies/${companyId}/team_definition/`);
    },
    setTeamSettings(data) {
      return axios.post(`api/v2/companies/${companyId}/team_definition/`, data);
    },
    vacancies: {
      getList(filters) {
        return __async(this, null, function* () {
          const filterUrl = filters.join();
          const list = yield axios.get(`/api/v2/vacancies/?status=${filterUrl}`);
          return list;
        });
      },
      getOne(id) {
        return axios.get(`/api/v2/vacancies/${id}/`);
      },
      getPublicPage() {
        return axios.get(`/api/v2/vacancy_page/`);
      },
      getOnePublic(id) {
        return axios.get(`/api/v2/vacancies/public/${id}/`);
      },
      create(data) {
        return axios.post(`/api/v2/vacancies/`, data);
      },
      update(data) {
        return axios.put(`/api/v2/vacancies/${data.id}/`, data);
      },
      delete(id) {
        return axios.delete(`/api/v2/vacancies/${id}/`);
      },
      updateStatus(data) {
        return axios.post(`api/v2/vacancies/${data.id}/update_status/`, { status: data.status });
      },
      updateVisibility(data) {
        return axios.patch(`api/v2/vacancies/${data.id}/`, { visibility: data.visibility });
      },
      getRounds(id) {
        return axios.get(`/api/v2/vacancies/${id}/rounds/`);
      },
      createRound(data) {
        return axios.post("/api/v2/rounds/", data);
      },
      updateRound(data) {
        return axios.put(`/api/v2/rounds/${data.id}/`, data);
      },
      deleteRound(id) {
        return axios.delete(`/api/v2/rounds/${id}/`);
      },
      setRecruiter(vacancy, employee) {
        return axios.post(`/api/v2/vacancies/${vacancy}/recruiters/`, employee);
      },
      /* eslint-disable camelcase */
      updateRecruiters(vacancy, employee_ids) {
        return axios.put(`/api/v2/vacancies/${vacancy}/recruiters/`, employee_ids);
      },
      /* eslint-enable camelcase */
      deleteRecruiter(vacancy, employee) {
        return axios.delete(`/api/v2/vacancies/${vacancy}/recruiters/`, {
          data: employee
        });
      },
      uploadFile(vacancyId, archive) {
        const formData = new FormData();
        formData.append("file", archive);
        return axios.post(`/api/v2/vacancies/${vacancyId}/files/`, formData, {
          headers: {
            "content-type": "multipart/form-data"
          }
        });
      },
      deleteFile(vacancyId, archiveId) {
        return axios.delete(`/api/v2/vacancies/${vacancyId}/files/${archiveId}/`);
      },
      getGeneralReport() {
        return axios.get(`/api/v2/vacancies/general_report/`, { responseType: "arraybuffer" });
      },
      getVacancyReport(vacancyId) {
        return axios.get(`/api/v2/vacancies/${vacancyId}/report/`, { responseType: "arraybuffer" });
      },
      getCandidatesReport(vacancyId) {
        return axios.get(`/api/v2/vacancies/${vacancyId}/candidate_report/`, { responseType: "arraybuffer" });
      }
    }
  };
}
const vacancies = {
  getRounds(id) {
    return axios.get(`/api/v2/vacancies/${id}/rounds/`).then(({ data }) => data);
  },
  changeCandidateVacancy(candidateId, vacancy) {
    return axios.patch(`/api/v2/candidates/${candidateId}/`, { vacancy });
  }
};
const customizedData = {
  getSections() {
    return axios.get(`/api/v2/section/`);
  },
  createSection(section) {
    return axios.post(`/api/v2/section/`, section);
  },
  updateSection(sectionId, section) {
    return axios.put(`/api/v2/section/${sectionId}/`, section);
  },
  deleteSection(sectionId) {
    return axios.post(`/api/v2/section/${sectionId}/delete/`);
  },
  deactivateSection(sectionId) {
    return axios.post(`/api/v2/section/${sectionId}/deactivate/`);
  },
  activateSection(sectionId) {
    return axios.post(`/api/v2/section/${sectionId}/activate/`);
  },
  getEmployeeCustomData(employeeId) {
    return axios.get(`/api/v2/employees/custom_data/${employeeId}/`);
  },
  getEmployeeCustomDataActive(employeeId) {
    return axios.get(`/api/v2/employees/custom_data/${employeeId}/fields_active/`);
  },
  updateEmployeeCustomData(employeeId, customData) {
    return axios.put(`/api/v2/employees/custom_data/${employeeId}/`, customData);
  }
};
const candidates = {
  create(data) {
    return axios.post(`/api/v2/candidates/`, data);
  },
  delete(id) {
    return axios.delete(`/api/v2/candidates/${id}`);
  },
  update(data) {
    return axios.put(`/api/v2/candidates/${data.id}/`, data);
  },
  getAll() {
    return axios.get(`/api/v2/candidates/`);
  },
  getList(id) {
    return axios.get(`/api/v2/vacancies/${id}/candidates/`);
  },
  getOne(id) {
    return axios.get(`/api/v2/candidates/${id}/`);
  },
  updateRound(data) {
    return axios.post(`/api/v2/candidates/${data.id}/update_round/`, {
      round: data.round,
      hired: data.hired,
      rejected: data.rejected
    });
  },
  uploadCV(id, file2) {
    const formData = new FormData();
    formData.append("file", file2);
    return axios.post(
      `/api/v2/candidates/${id}/upload_cv/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
  },
  uploadExtraFile(id, file2) {
    const formData = new FormData();
    formData.append("file", file2);
    return axios.post(
      `/api/v2/candidates/${id}/files/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
  }
};
const comments = {
  getList(id) {
    return axios.get(`/api/v2/candidates/${id}/comments/`);
  },
  create(data, id) {
    return axios.post(`/api/v2/candidates/${id}/comments/`, data);
  }
};
function meEmployee() {
  return axios.get("/users/me/employee/");
}
function getOrgTree(boss = null) {
  const queryParms = {};
  if (boss) {
    queryParms.boss = boss;
  }
  return axios.get("api/v2/employees/tree/", { params: queryParms }).then(({ data }) => data);
}
function getOrgTreeSVG() {
  return axios.get("api/v2/employees/tree/svg/");
}
function getUsers() {
  return axios.get(`/users/`);
}
function getUser(id) {
  return axios.get(`/users/${id}/`);
}
function inviteUser(data) {
  return axios.post("/users/", data);
}
function setUserPassword(data) {
  return axios.post("/users/set_password/", data);
}
function setUserRole(data) {
  return axios.post("/users/set_role/", data);
}
function verifyUser(id) {
  return axios.get(`/users/verify/${id}/`, { withCredentials: false });
}
function resetPassword(username) {
  const uninterceptedAxiosInstance = axios.create();
  return uninterceptedAxiosInstance.post("/users/reset_password/", { username }).catch((error) => error.response);
}
function magicLink(id) {
  return axios.post(`/api/v1/profile/private/${id}/magic_link/`).catch((error) => error.response);
}
function getTokens({ username, password }) {
  return axios.post("/token/", { username, password });
}
function login({ token, refresh }) {
  setSessionTokens(token, refresh);
  updateAxiosToken();
}
function getCompanyIncidenciasSettings() {
  return axios.get("/companies/incidencias_settings/");
}
function updateCompanyIncidenciasSettings(data) {
  return axios.put("/companies/incidencias_settings/", data);
}
const massiveEmployees = {
  getExcel() {
    return axios.get(
      "/api/v1/massive_create/download_template/",
      { responseType: "arraybuffer" }
    );
  },
  uploadExcel(file2, companyId) {
    const formData = new FormData();
    formData.append("file", file2);
    return axios.post(
      `api/v1/massive_create/${companyId}/upload_excel/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
  }
};
const incidencias = {
  getSettings() {
    return axios.get("/companies/incidencias_settings/");
  },
  getCustomSettings() {
    return axios.get(`api/v2/custom_incidences_template`);
  },
  updateLegacyIncidencesSettings(data) {
    return axios.put("/companies/incidencias_settings/", data);
  },
  getAreasInfo() {
    return axios.get(`api/v2/areas/?use_min=true`);
  }
};
const editRequest = {
  incidenceOpened(incidenceId) {
    return axios.post(`api/v2/incidences/${incidenceId}/seen/`);
  },
  getIncidenceInfo(idIncidence, startDate, endDate) {
    if (startDate && endDate) {
      return axios.get(`api/v2/incidences/${idIncidence}/metadetail/?start_date=${startDate}&end_date=${endDate}`);
    }
    return axios.get(`api/v2/incidences/${idIncidence}/metadetail/`);
  },
  postCommentOnIncidence(data) {
    return axios.post(`api/v1/comments/`, data);
  },
  editComment(data, id) {
    return axios.patch(`api/v1/comments/${id}/`, data);
  },
  deleteComment(id) {
    return axios.delete(`api/v1/comments/${id}/`);
  },
  deleteFileComment(idComment, idFile) {
    return axios.delete(`api/v1/comments/${idComment}/files/${idFile}/`);
  },
  attachFileToComment(data, id) {
    const bodyFormData = new FormData();
    bodyFormData.append("file", data.file);
    bodyFormData.append("filename", data.filename);
    return axios.post(`api/v1/comments/${id}/files/`, bodyFormData);
  },
  getIncidencesForReport({ idCompany, startDate, endDate }) {
    return axios.get(`api/v2/companies/${idCompany}/incidences?start_date_gte=${startDate}&end_date_lte=${endDate}`);
  },
  addfiles(requestId, file2, is_create) {
    const bodyFormData = new FormData();
    bodyFormData.append("file", file2);
    let params = "";
    if (is_create) {
      params = `?is_create=${is_create}`;
    }
    return axios({
      method: "POST",
      url: `/api/v2/requests/${requestId}/files/${params}`,
      data: bodyFormData
    });
  },
  addfilesIncidence(requestId, file2, is_create) {
    const bodyFormData = new FormData();
    bodyFormData.append("file", file2);
    let params = "";
    if (is_create) {
      params = `?is_create=${is_create}`;
    }
    return axios({
      method: "POST",
      url: `/api/v2/incidences/${requestId}/files/${params}`,
      data: bodyFormData
    });
  },
  deleteRequest(requestId, isHra) {
    return isHra ? axios.delete(`/api/v2/incidences/${requestId}`) : axios.delete(`/api/v2/requests/${requestId}`);
  },
  deleteFiles(requestId, fileId) {
    return axios.delete(`/api/v2/requests/${requestId}/files/${fileId}`);
  },
  deleteFilesIncident(incId, fileId) {
    return axios.delete(`/api/v2/incidences/${incId}/files/${fileId}`);
  },
  offDays(data) {
    return axios.post(`api/v2/employee/days_off/`, data);
  },
  offDaysEm(id) {
    return axios.get(`api/v2/employees/${id}/available_requests/`);
  },
  updateDaysOff(data) {
    return axios.put(`api/v2/employee_days_off/bulk_update/`, data);
  },
  getEmployeesByArea(data) {
    return axios.get(`api/v2/employee_days_off/?area_in=${data}`);
  },
  bulkDaysOff(data) {
    return axios.put(`api/v2/employee_days_off/bulk_update_days/`, data);
  },
  update({ id, data }) {
    return axios.put(`api/v2/requests/${id}/`, data);
  },
  updateIncident({ id, data }) {
    return axios.put(`api/v2/incidences/${id}/`, data);
  },
  addReviewerFiles(reviewerId, file2) {
    const bodyFormData = new FormData();
    bodyFormData.append("file", file2);
    return axios({
      method: "POST",
      url: `/api/v2/review_flow/reviewers/${reviewerId}/files/`,
      data: bodyFormData
    });
  },
  remindRequest(id) {
    return axios.post(`/api/v2/requests/${id}/remind/`);
  },
  approveRequest(body) {
    return axios.post(`/api/v2/requests/bulk_approve/`, body);
  },
  cancelRequest(body) {
    return axios.post(`/api/v2/incidences/bulk_cancel/`, body);
  },
  removeRequest(ids) {
    return axios.delete(
      `/api/v2/incidences/bulk_destroy/`,
      {
        data: {
          incidence_ids: ids
        }
      }
    );
  },
  approveSingleRequest(id, reason) {
    return axios.post(`api/v2/requests/${id}/approve/`, reason);
  },
  bulkApproveRequest(body) {
    return axios.post(`api/v1/time_clock/company/bulk_approve/`, body);
  },
  rejectRequest({ id }, reason) {
    return axios.post(`/api/v2/requests/${id}/reject/`, reason);
  },
  forceApproveRequest({ id }, reason) {
    return axios.post(`/api/v2/requests/${id}/force_approve/`, reason);
  },
  forceRejectRequest({ id }, reason) {
    return axios.post(`/api/v2/requests/${id}/force_reject/`, reason);
  },
  downloadIncidencesFile(startDate, endDate, statuses, legalName, areas2, myTeam) {
    const team = myTeam ? "&team=true" : "";
    let url = `/api/v2/incidences/download/?dates=${startDate},${endDate}
      &razon_social_in=${legalName}&area_in=${areas2}${team}`;
    if (statuses) {
      url = statuses.reduce((statusString, stat, idx) => idx === 0 ? url.concat(`&incidence_status_in=${stat}`) : statusString.concat(`,${stat}`), "");
    }
    return axios.get(`${url}`, { responseType: "arraybuffer" });
  },
  sendDownloadIncidences(sendTo, startDate, endDate, legalName) {
    return axios.post(
      `/api/v2/incidences/download/?dates=${startDate},${endDate}`,
      { email: sendTo, legal_name: legalName }
    );
  },
  // notifyIncidences (companyId) {
  //   return axios.post(`/api/v2/companies/${companyId}/notify_incidences/`)
  // },
  notifyIncidencesV2() {
    return axios.post(`/api/v2/requests/notify_incidences/`);
  },
  sendDownloadPendingIncidences(sendTo, query) {
    return axios.post(
      `/api/v2/requests/download/?${query}`,
      { email: sendTo }
    );
  },
  downloadPendingIncidencesFile(query) {
    return axios.get(
      `/api/v2/requests/download/?${query}`,
      { responseType: "arraybuffer" }
    );
  }
};
const encuestas = {
  getAnswers(id) {
    return axios.get(`/api/v2/companies/${id}/sentiment_surveys/`);
  },
  getConfig(id) {
    return axios.get(`/api/v1/survey/config/${id}/`);
  },
  setAnswer(id, response) {
    return axios.post(`/api/v2/employees/${id}/sentiment_survey/`, response);
  },
  updateConfig(id, data) {
    return axios.put(`/api/v1/survey/config/${id}/`, data);
  }
};
const timeClock = {
  getTimeClockConfig() {
    return axios.get(`api/v1/time_clock/configuration/`);
  },
  getAutoApproveSetting(companyId) {
    return axios.get(`api/v1/time_clock/settings/${companyId}/`);
  },
  switchAutoApproveConfig(companyId, newConfig) {
    return axios.put(`api/v1/time_clock/settings/${companyId}/`, newConfig);
  },
  updateTimeClockConfig(timeClockConfigId, newConfig) {
    return axios.put(`api/v1/time_clock/configuration/${timeClockConfigId}/`, newConfig);
  },
  updateOffice(office) {
    const { id } = office;
    return axios.put(`/api/v2/locations/${id}/`, office);
  },
  downloadWorkedHours({ companyId, startDate, endDate }) {
    let fullUrl = `api/v1/time_clock/company/${companyId}/download_summary/?`;
    if (startDate) {
      fullUrl = `${fullUrl}start_date=${startDate}&`;
    }
    if (endDate) {
      if (!startDate) {
        const mockTodayStart = DateTime.local().toISODate();
        fullUrl = `${fullUrl}start_date=${mockTodayStart}&end_date=${endDate}`;
      } else {
        fullUrl = `${fullUrl}end_date=${endDate}`;
      }
    }
    return axios.get(`${fullUrl}`, { responseType: "arraybuffer" });
  },
  workedHours({ companyId, startDate, endDate }) {
    let fullUrl = `api/v1/time_clock/company/${companyId}/summary/?`;
    if (startDate) {
      fullUrl = `${fullUrl}start_date=${startDate}&`;
    }
    if (endDate) {
      if (!startDate) {
        const mockTodayStart = DateTime.local().toISODate();
        fullUrl = `${fullUrl}start_date=${mockTodayStart}&end_date=${endDate}`;
      } else {
        fullUrl = `${fullUrl}end_date=${endDate}`;
      }
    }
    return axios.get(`${fullUrl}`);
  },
  pendings({
    companyId,
    startDate,
    endDate,
    status,
    area_in,
    location_in,
    incidence_type_in,
    owner_full_name
  }) {
    let fullUrl = `api/v2/companies/${companyId}/incidences/?is_reviewer=true&time_clock=true&status=${status || "PEN"}&`;
    if (startDate) {
      fullUrl = `${fullUrl}end_date_gte=${startDate}&`;
      if (!endDate) {
        fullUrl.concat(`start_date_lte=${startDate}`);
      }
    }
    if (endDate) {
      if (!startDate) {
        const mockTodayStart = DateTime.local().toISODate();
        fullUrl = `${fullUrl}end_date_gte=${mockTodayStart}&start_date_lte=${endDate}`;
      } else {
        fullUrl = `${fullUrl}&start_date_lte=${endDate}`;
      }
    }
    if (area_in && area_in.length > 0) {
      fullUrl = `${fullUrl}&area_in=${area_in}`;
    }
    if (location_in && location_in.length > 0) {
      fullUrl = `${fullUrl}&location_in=${location_in}`;
    }
    if (incidence_type_in && incidence_type_in.length > 0) {
      fullUrl = `${fullUrl}&incidence_type_in=${incidence_type_in}`;
    }
    if (owner_full_name && owner_full_name.length > 0) {
      fullUrl = `${fullUrl}&owner_full_name=${owner_full_name}`;
    }
    return axios.get(`${fullUrl}`);
  },
  sendValidateFile(file2, companyId) {
    const formData = new FormData();
    formData.append("file", file2);
    return axios.post(
      `/api/v2/companies/${companyId}/validate_file/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
  },
  sendUploadAssistances(file2, companyId) {
    const formData = new FormData();
    formData.append("file", file2);
    return axios.post(
      `/api/v2/companies/${companyId}/upload_assistances/`,
      formData,
      {
        headers: {
          "content-Type": "multipart/form-data"
        }
      }
    );
  },
  downloadTemplate(companyId) {
    return axios.get(`/api/v2/companies/${companyId}/time_clock_excel/`, { responseType: "arraybuffer" });
  },
  info(employeeId, { timeZone } = {}) {
    let baseUrl = `api/v1/time_clock/employee/${employeeId}/information/`;
    if (timeZone)
      baseUrl = `${baseUrl}?timezone=${timeZone}`;
    return axios.get(baseUrl);
  },
  checkin(employeeId, data) {
    return axios.post(`api/v1/time_clock/web/${employeeId}/checkin/`, data);
  },
  checkout(employeeId, data) {
    return axios.post(`api/v1/time_clock/web/${employeeId}/checkout/`, data);
  },
  myWorkedHours(employeeId, { startDate, endDate } = {}) {
    const start = startDate || DateTime.local().startOf("month").toISODate();
    const end = endDate || DateTime.local().endOf("month").toISODate();
    const url = `api/v1/time_clock/employee/${employeeId}/summary/?start_date=${start}&end_date=${end}`;
    return axios.get(url);
  },
  // Pruebas v3
  getOneSchedule(scheduleId) {
    return axios.get(`api/v1/schedules/${scheduleId}/`);
  },
  employeesSchedules() {
    return axios.get(`/api/v1/schedules/`);
  },
  getSchedules({ pageNumber }, options) {
    let paginationText = "";
    if (pageNumber > 1) {
      paginationText = `page=${pageNumber}`;
    }
    const params = [
      paginationText,
      ...!options.filter ? [] : [`full_name_or_puesto=${options.filter}`],
      ...Object.keys(options).filter((opt) => !["filter", "sort"].includes(opt) && options[opt]).map((opt) => `${opt}=${options[opt] === "without" ? null : options[opt]}`),
      `order_by=${options.sort}`
    ].filter((param) => param).join("&");
    return axios.get(`api/v1/personal_schedules/list_employees/?${params}`).then(({ data }) => data);
  },
  createShift() {
    return axios.post(`/api/v1/schedules/`);
  },
  assignSchedule(scheduleId) {
    return axios.post(`/api/v1/schedules/${scheduleId}/assign_employees/`);
  },
  getRegisters(filters) {
    let filterParams = "";
    if (filters) {
      filterParams = Object.keys(filters).map((filterParam) => `${filterParam}=${filters[filterParam]}`).join("&");
    }
    return axios.get(`/api/v1/status_timework/employee_records/?${filterParams}`).then(({ data }) => data);
  },
  myStatus({ timeZone } = {}) {
    let baseUrl = `api/v1/status_timework/me/`;
    if (timeZone)
      baseUrl = `${baseUrl}?timezone=${timeZone}`;
    return axios.get(baseUrl).then(({ data }) => data);
  },
  checkIn(shiftId, data) {
    return axios.post(`/api/v1/register/${shiftId}/checkin/`, data);
  },
  checkOut(shiftId, data) {
    return axios.post(`/api/v1/register/${shiftId}/checkout/`, data);
  },
  mySchedule() {
    return axios.get(`api/v1/personal_schedules/my_schedule`).then(({ data }) => data);
  },
  statusTimework(params = {}, options) {
    const queryParams = { startDate: "start_date", endDate: "end_date" };
    let queryParamsString = "";
    if (params) {
      queryParamsString = Object.keys(queryParams).map((queryParam) => `${queryParams[queryParam]}=${params[queryParam]}`).join("&");
    }
    const query = [
      queryParamsString,
      `page=${options.page}`,
      `order_by=${options.sort}`,
      ...options.incidenceType ? [`incidence_type=${options.incidenceType}`] : []
    ].join("&");
    return axios.get(`/api/v1/status_timework/my_status_records/?${query}`).then(({ data }) => data);
  },
  myRecords(params = {}, options) {
    const queryParams = { startDate: "start_date", endDate: "end_date" };
    let queryParamsString = "";
    if (params) {
      queryParamsString = Object.keys(queryParams).map((queryParam) => `${queryParams[queryParam]}=${params[queryParam]}`).join("&");
    }
    const query = [
      queryParamsString,
      `page=${options.page}`,
      `order_by=${options.sort}`,
      ...options.incidenceType ? [`incidence_type=${options.incidenceType}`] : []
    ].join("&");
    return axios.get(`api/v1/status_timework/my_records/?${query}`).then(({ data }) => data);
  },
  employeesWorkedHours({ startDate, endDate }) {
    let fullUrl = `api/v1/status_timework/employee_workinghours/`;
    if (startDate) {
      fullUrl = `${fullUrl}?start_date=${startDate}&`;
    }
    if (endDate) {
      if (!startDate) {
        const mockTodayStart = DateTime.local().toISODate();
        fullUrl = `${fullUrl}start_date=${mockTodayStart}&end_date=${endDate}`;
      } else {
        fullUrl = `${fullUrl}end_date=${endDate}`;
      }
    }
    return axios.get(`${fullUrl}`).then(({ data }) => data);
  },
  getSchedulesHRA(page = 1, search) {
    const pagination = `?page=${page}`;
    let searchText = "";
    if (search) {
      searchText = `&search=${search}`;
    }
    return axios.get(`api/v1/schedules/${pagination}${searchText}`).then(({ data }) => data);
  },
  createScheduleTemplate(scheduleTemplate) {
    return axios.post("api/v1/schedules/", scheduleTemplate).then(({ data }) => data);
  },
  assignScheduleTemplateToEmployee(scheduleTemplateID, params) {
    return axios.post(`/api/v1/schedules/${scheduleTemplateID}/assign_employees/`, params).then(({ data }) => data);
  },
  deleteScheduleTemplate(scheduleTemplateID) {
    return axios.delete(`api/v1/schedules/${scheduleTemplateID}/`).then(({ data }) => data);
  },
  updateScheduleTemplate(scheduleTemplate, applyNow) {
    return axios.put(`api/v1/schedules/${scheduleTemplate.id}/?apply_now=${applyNow}`, scheduleTemplate).then(({ data }) => data);
  },
  employeeWorkingHours(params, options) {
    const queryParams = { startDate: "start_date", endDate: "end_date" };
    let queryParamsString = "";
    if (params) {
      queryParamsString = Object.keys(queryParams).map((queryParam) => `${queryParams[queryParam]}=${params[queryParam]}`).join("&");
    }
    const query = [
      queryParamsString,
      `page=${options.currentPage}`,
      ...options.area ? [`area=${options.area}`] : [],
      ...options.searchText ? [`full_name=${options.searchText}`] : [],
      ...options.status ? [`status=${options.status}`] : [],
      `order_by=${options.sort}`
    ].filter((q) => q).join("&");
    return axios.get(`/api/v1/status_timework/employee_workinghours/?${query}`).then(({ data }) => data);
  },
  getTimeWorkedDetail(employeeId, params) {
    const queryParams = { startDate: "start_date", endDate: "end_date" };
    let queryParamsString = "";
    if (params) {
      queryParamsString = Object.keys(queryParams).map((queryParam) => `${queryParams[queryParam]}=${params[queryParam]}`).join("&");
    }
    return axios.get(`/api/v1/status_timework/${employeeId}/workinghours_detail/?${queryParamsString}`).then(({ data }) => data);
  },
  getTimePolicies(countPage, search) {
    let searchText = "";
    if (search) {
      searchText = `&search=${search}`;
    }
    return axios.get(`api/v1/time_policies/?page=${countPage}${searchText}`).then(({ data }) => data);
  },
  getEmployeesPolicies(currentPage, options) {
    let pageText = "";
    if (currentPage > 1) {
      pageText = `page=${currentPage}`;
    }
    const query = [
      pageText,
      ...!options.search ? [] : [`full_name_or_puesto=${options.search}`],
      ...!options.area ? [] : [`area=${options.area}`],
      ...!options.policy ? [] : [`policy=${options.policy}`],
      ...!options.location ? [] : [`location_in=${options.location}`],
      `order_by=${options.sort}`
    ].filter((q) => q).join("&");
    return axios.get(`/api/v1/personal_time_policies/list_employees/?${query}`).then(({ data }) => data);
  },
  getEmployeesPoliciesFilters(params) {
    let queryParamsString = "";
    if (params) {
      queryParamsString = `?${Object.keys(params).map((queryParam) => `${queryParam}=${params[queryParam]}`).join("&")}`;
    }
    return axios.get(`/api/v1/personal_time_policies/list_employees/${queryParamsString}`).then(({ data }) => data);
  },
  createPolicy(policy) {
    return axios.post(`/api/v1/time_policies/`, policy);
  },
  assignPolicy(policyId) {
    return axios.post(`/api/v1/time_policies/${policyId}/assign_employees/`);
  },
  getWorkedHoursReport(params) {
    let queryParamsString = "";
    if (params) {
      queryParamsString = Object.keys(params).map((queryParam) => `${queryParam}=${params[queryParam]}`).join("&");
    }
    return axios.get(`/api/v1/status_timework/download_report_workinghours/?${queryParamsString}`, { responseType: "arraybuffer" }).then(({ data }) => data);
  },
  getRecordsReport(params, preload = false) {
    let queryParamsString = "";
    if (params) {
      queryParamsString = Object.keys(params).map((key) => `${key}=${params[key]}`).join("&");
    }
    if (preload) {
      return axios.get(`/api/v1/status_timework/download_report_records/?pre_build=true&${queryParamsString}`, { responseType: "arraybuffer" }).then(({ data }) => data);
    }
    return axios.get(`/api/v1/status_timework/download_report_records/?${queryParamsString}`, { responseType: "arraybuffer" }).then(({ data }) => data);
  },
  getIncidencesReport(params) {
    const queryParamsString = Object.keys(params).map((param) => `${param}=${params[param]}`).join("&");
    return axios.get(`/api/v1/status_timework/download_report_incidences/?${queryParamsString}`, { responseType: "arraybuffer" }).then(({ data }) => data);
  },
  getTimePolicy(policyId) {
    return axios.get(`api/v1/time_policies/${policyId}/`).then(({ data }) => data);
  },
  updatePolicy(policyId, policy) {
    return axios.put(`/api/v1/time_policies/${policyId}/`, policy);
  },
  updateTimeOption(optionId, option) {
    return axios.put(`/api/v1/time_options/${optionId}/`, option);
  },
  setPolicyToEmployee(policyId, employeeList) {
    return axios.post(`/api/v1/time_policies/${policyId}/assign_employees/`, employeeList);
  },
  sendCommentCheck(recordId, commentData) {
    const formData = new FormData();
    Object.keys(commentData).forEach((key) => {
      if (Array.isArray(commentData[key])) {
        const items = commentData[key];
        items.forEach((item) => {
          formData.append(`${key}`, item);
        });
      } else {
        formData.append(key, commentData[key]);
      }
    });
    return axios.put(`/api/v1/records/${recordId}/add_comment/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  },
  /* eslint-disable camelcase */
  updateRule({ id, show_rule }) {
    return axios.put(`api/v1/time_rules/${id}/`, { show_rule });
  },
  getMyPolicy() {
    return axios.get("api/v1/personal_time_policies/my_policy/").then(({ data }) => data);
  },
  getMyOffices() {
    return axios.get("/api/v1/status_timework/offices/").then(({ data }) => data);
  },
  getTimeClockConfiguration() {
    return axios.get("/api/v1/timework_personal_settings/list_employees/").then(({ data }) => data);
  },
  updateEmployeeTimeClockConfig(id, configData) {
    return axios.put(`/api/v1/timework_personal_settings/${id}/`, configData).then(({ data }) => data);
  },
  updateMassiveEmployeeTimeClockConfig(configData) {
    return axios.put("/api/v1/timework_personal_settings/massive_update/", configData).then(({ data }) => data);
  },
  updateRuleBulk(rules) {
    return axios.put(`/api/v1/time_rules/bulk_update/`, rules);
  },
  updateTimeOptionBulk(options) {
    return axios.put("/api/v1/time_options/bulk_update/", options).then(({ data }) => data);
  },
  deleteTimePolicy(timePolicyId) {
    return axios.delete(`/api/v1/time_policies/${timePolicyId}`).then(({ data }) => data);
  },
  removePolicyToEmployee(employeeList) {
    return axios.post("/api/v1/time_policies/unassign_employees/", {
      employee_ids: employeeList
    }).then(({ data }) => data);
  },
  removePolicyToEmployeeNoReasign(body) {
    return axios.post("/api/v1/time_policies/unassign_employees/", body).then(({ data }) => data);
  },
  getEmployeesClock(isflex) {
    const flex = isflex ? "&register_flex_active=false" : "";
    return axios.get(`/api/v2/employees/directory/?use_list=true&use_records=true&active=true${flex}`);
  },
  getStatusTimework(employeeId, date) {
    return axios.get(`/api/v1/status_timework/${employeeId}/?request_date=${date}`).then(({ data }) => data);
  },
  addNewRecords(records) {
    return axios.post("/api/v1/records/", records);
  },
  editRecords(record) {
    return axios.patch(`/api/v1/records/${record.id}/`, record);
  },
  getClarifications() {
    return axios.get(`/api/v1/clarifications/`).then(({ data }) => data);
  },
  getClarification(id) {
    return axios.get(`/api/v1/clarifications/${id}/`).then(({ data }) => data);
  },
  resolveNoChanges(id, body) {
    return axios.put(`api/v1/clarifications/${id}/`, body).then(({ data }) => data);
  },
  addCommentToClarification(idClarification, formdata) {
    return axios.put(`/api/v1/clarifications/${idClarification}/add_comment/`, formdata, {
      headers: {
        "content-type": "multipart/form-data"
      }
    });
  },
  getRecordToEditClarification(idRecord, clarifId) {
    const clarification = clarifId ? `?clarification_id=${clarifId}` : "";
    return axios.get(`/api/v1/records/${idRecord}/status/${clarification}`).then(({ data }) => data);
  },
  getTimeClockRegisterTypes() {
    return axios.get("/api/v1/timework_personal_settings/detail_status/").then(({ data }) => data);
  },
  putWhatsApp(data) {
    return axios.put(`/api/v1/timework_personal_settings/massive_update/`, data);
  },
  sendWelcome(data) {
    return axios.post("/api/v1/timework_personal_settings/send_welcome/", data);
  },
  downloadExcelWa(employeeIds, all) {
    let ids = "";
    let process2 = "";
    if (employeeIds && employeeIds.length > 0) {
      ids = `&employee_ids=${employeeIds}`;
    }
    if (!all) {
      process2 = "?process=update";
    }
    return axios.get(
      `/api/v1/timework_personal_settings/download_template/${process2}${ids}`,
      { responseType: "arraybuffer" }
    );
  },
  uploadExcelWa(excelFile) {
    const formData = new FormData();
    formData.append("file", excelFile);
    return axios.post(`/api/v1/timework_personal_settings/massive_update_excel/`, formData, {
      headers: {
        "content-type": "multipart/form-data"
      }
    });
  },
  getAttendancesStatus(params) {
    let queryParamsString = "";
    if (params) {
      queryParamsString = Object.keys(params).map((queryParam) => `${queryParam}=${params[queryParam]}`).join("&");
    }
    return axios.get(`/api/v1/attendances/detail_status/?${queryParamsString}`).then(({ data }) => data);
  },
  getAttendanceReport(params) {
    let queryParamsString = "";
    if (params) {
      queryParamsString = Object.keys(params).map((queryParam) => `${queryParam}=${params[queryParam]}`).join("&");
    }
    return axios.get(`/api/v1/attendances/download_report_attendance/?${queryParamsString}`, { responseType: "arraybuffer" }).then(({ data }) => data);
  },
  // obtiene las incidencias vinculadas que evitan falta
  getLinkedIncidents(filters) {
    let queryParamsString = "";
    if (filters) {
      queryParamsString += "?";
      queryParamsString += Object.keys(filters).map((queryParam) => `${queryParam}=${filters[queryParam]}`).join("&");
    }
    return axios.get(`/api/v2/custom_incidences_template/${queryParamsString}`).then(({ data }) => data);
  },
  // Endpoints programacion de turnos
  getSchedulesShifts(params) {
    let queryParamsString = "";
    if (params) {
      queryParamsString = `?${Object.keys(params).map((queryParam) => `${queryParam}=${params[queryParam]}`).join("&")}`;
    }
    return axios.get(`/api/v1/scheduled_shifts/${queryParamsString}`).then(({ data }) => data);
  },
  processCopyShift(payload) {
    return axios.post("/api/v1/scheduled_shifts/process_copy_shift/", payload);
  },
  processReplaceShift(payload) {
    return axios.post("/api/v1/scheduled_shifts/process_replace_shift/", payload);
  },
  processDragAndDrop(payload) {
    return axios.post("/api/v1/scheduled_shifts/process_drag_and_drop/", payload);
  },
  processDeleteShift(payload) {
    return axios.delete("/api/v1/scheduled_shifts/process_clean_shift/", { data: payload });
  },
  processCopyRow(payload) {
    return axios.post("/api/v1/scheduled_shifts/process_copy_row/", payload);
  },
  createMasterShift(payload) {
    return axios.post("/api/v1/scheduled_shifts/", payload);
  },
  updateMasterShift(payload, id) {
    return axios.put(`/api/v1/scheduled_shifts/${id}/`, payload);
  },
  deleteMasterShift(id) {
    return axios.delete(`/api/v1/scheduled_shifts/${id}/`);
  },
  processCleanWeek(payload) {
    return axios.delete(`/api/v1/scheduled_shifts/process_clean_week/`, { data: payload });
  },
  processCopyRange(payload) {
    return axios.post(`/api/v1/scheduled_shifts/process_copy_range/`, payload);
  },
  processRepeatRange(payload) {
    return axios.post(`/api/v1/scheduled_shifts/process_repeat_range/`, payload);
  },
  processShareSchedule(payload) {
    return axios.post(`/api/v1/scheduled_shifts/process_share_schedule/`, payload);
  },
  processDownloadSchedule(params) {
    let queryParamsString = "";
    if (params) {
      queryParamsString = `?${Object.keys(params).map((key) => `${key}=${params[key]}`).join("&")}`;
    }
    return axios.get(`/api/v1/scheduled_shifts/employees/download_report_scheduled_shifts/${queryParamsString}`, { responseType: "arraybuffer" }).then(({ data }) => data);
  },
  getSchedulesDetailStatus() {
    return axios.get(`/api/v1/schedules/detail_status/`).then(({ data }) => data);
  },
  getPrevShifts() {
    return axios.get(`/api/v1/shifts/`).then(({ data }) => data);
  },
  processCreateFromShifts(payload) {
    return axios.post("/api/v1/scheduled_shifts/process_create_from_shifts/", payload);
  },
  processApplyNow(payload) {
    return axios.post("/api/v1/scheduled_shifts/process_apply_now/", payload);
  },
  processDownloadReport() {
    return axios.get(`/api/v1/scheduled_shifts/download_report/`, { responseType: "arraybuffer" }).then(({ data }) => data);
  },
  // multioffices
  updateTimeworkOffices(id, payload, replace) {
    let action = "alter";
    if (replace) {
      action = "replace";
    }
    return axios.put(`/api/v1/timework_offices/${id}/${action}/`, payload);
  },
  updatemassiveTimeworkOffices(payload, replace) {
    let action = "massive_add";
    if (replace) {
      action = "massive_replace";
    }
    return axios.post(`/api/v1/timework_offices/${action}/`, payload);
  },
  getEmsTimeworkOffices(params) {
    let queryParamsString = "";
    if (params) {
      queryParamsString = Object.keys(params).map((queryParam) => `${queryParam}=${params[queryParam]}`).join("&");
    }
    return axios.get(`/api/v1/timework_offices/employees/?${queryParamsString}`).then(({ data }) => data);
  },
  cleanOffcies(payload) {
    return axios.post(`/api/v1/timework_offices/massive_clean/`, payload);
  },
  getCompanyConfig() {
    return axios.get(`/api/v1/timework_settings/company/`);
  },
  setCompanyConfig(id, payload) {
    return axios.put(`/api/v1/timework_settings/${id}/`, payload);
  },
  getIncidenceTimeworkConfig(params) {
    let queryParamsString = "";
    if (params) {
      queryParamsString = Object.keys(params).map((queryParam) => `${queryParam}=${params[queryParam]}`).join("&");
    }
    return axios.get(`/api/v1/timework_incidence_settings/?${queryParamsString}`);
  },
  setIncidenceTimeworkConfig(id, payload) {
    return axios.put(`/api/v1/timework_incidence_settings/${id}/`, payload);
  },
  bulkIncidencesTimeworkConfig(payload) {
    return axios.put(`/api/v1/timework_incidence_settings/bulk_update/`, payload);
  },
  // timeclock reports
  getSettingsTimeclockReport() {
    return axios.get("/api/v1/timework_personal_settings_report/").then(({ data }) => data);
  },
  bulkSettingsTimeclockReport(payload) {
    return axios.put("/api/v1/timework_personal_settings_report/bulk_update/", payload).then(({ data }) => data);
  },
  resetSettingsTimeclockReport() {
    return axios.post("/api/v1/timework_personal_settings_report/reset/");
  },
  //masive clarifications
  resolveMasiveClarifications(payload) {
    return axios.patch("/api/v1/clarifications/massive-update/", payload);
  },
  masiveCreateRegisterClarification(payload) {
    return axios.post("/api/v1/records/massive-create/", payload);
  },
  getCatalogsTimework() {
    return axios.get("/api/v1/timework/catalogs/").then(({ data }) => data);
  },
  // settings scheduling absences
  getAbsenceSettings() {
    return axios.get("/api/v2/incidences/absence-settings/").then(({ data }) => data);
  },
  saveAbsenceSettings(payload) {
    return axios.post("/api/v2/incidences/absence-settings/", payload);
  },
  updateAbsenceSettings(payload) {
    return axios.patch("/api/v2/incidences/absence-settings/", payload);
  },
  removeTemplateShift(payload) {
    return axios.post("/api/v1/personal_schedules/clean-shifts/", payload);
  },
  isApproverProcess(payload) {
    return axios.post(`/api/v1/internal-process/check_employee_is_reviewer/`, payload);
  }
};
const file = {
  sendUploadFile({ fileRef, id }) {
    const bodyFormData = new FormData();
    bodyFormData.append("file", fileRef);
    bodyFormData.append("object_id", id);
    return axios({
      method: "post",
      url: "/api/v1/files/",
      data: bodyFormData
    });
  },
  getFiles() {
    return axios.get("/api/v1/files/");
  },
  deleteFile(id) {
    return axios.delete(`api/v1/files/${id}`);
  },
  get(documentId) {
    return axios.get(`/api/v1/files/documents/${documentId}/document/`, {
      responseType: "arraybuffer"
    });
  }
};
const customIncidences = {
  getCustomIncidences(companyId) {
    return axios.get(`api/v2/companies/${companyId}/custom_incidences_template`);
  },
  getIncidencesType() {
    return axios.get(`api/v2/custom_incidences_template/legacy_types/`);
  },
  getIncidencesCategory() {
    return axios.get(`api/v2/incidences_category/`);
  },
  getCustomIncidencesData(id) {
    return axios.get(`api/v2/custom_incidences_template/${id}/`);
  },
  createNewCustomIncidenceTemplate(data) {
    return axios.post(`/api/v2/custom_incidences_template/`, data);
  },
  editCustomIncidenceTemplate(id, data) {
    return axios.put(`/api/v2/custom_incidences_template/${id}/`, data);
  },
  deleteCustomIncidenceTemplate(id) {
    return axios.delete(`/api/v2/custom_incidences_template/${id}/`);
  }
};
const massiveIncidences = {
  createMassiveIncidences(data) {
    return axios.post(`/api/v2/incidences/massive_create/`, data);
  }
};
const payments = {
  getFirstPaymentInformation(companyId) {
    return axios.get(`api/v1/integrations/payment_plans/${companyId}/first_payment/`, {
      validateStatus(status) {
        return status < 600;
      }
    });
  },
  payFirstPayment(data, companyId) {
    return axios.post(`api/v1/integrations/payment_plans/make_payment/`, data, {
      validateStatus(status) {
        return status < 600;
      }
    });
  },
  getFirstPaymentDetail(companyId) {
    return axios.get(`api/v1/integrations/payment_plans/${companyId}/plan/`);
  }
};
const levels = {
  getLevels() {
    return axios.get(`api/v2/employees/payroll_levels/`);
  },
  createNewLevel(data) {
    return axios.post(`/api/v2/employees/payroll_levels/`, data);
  },
  editLevel(id, data) {
    return axios.put(`/api/v2/employees/payroll_levels/${id}/`, data);
  },
  deleteLevel(id) {
    return axios.delete(`/api/v2/employees/payroll_levels/${id}/`);
  }
};
const businessUnits = {
  getBusinessUnits() {
    return axios.get(`api/v2/business_units/`);
  },
  createNewBusinessUnit(data) {
    return axios.post(`api/v2/business_units/`, data);
  },
  editBusinessUnit(id, data) {
    return axios.put(`api/v2/business_units/${id}/`, data);
  },
  deleteBusinessUnit(id) {
    return axios.delete(`api/v2/business_units/${id}/`);
  }
};
const prepayroll = {
  getPrepayroll() {
    return axios.get(`/api/v1/prepayroll/incidence_configuration/`);
  },
  massiveUpdatePrepayroll(incidences2) {
    return axios.post(`/api/v1/prepayroll/incidence_configuration/bulk_update/`, incidences2);
  },
  sendMailLayout(idCompany, sendTo, query) {
    return axios.post(
      `/api/v2/companies/${idCompany}/send_noi_layout/${query}`,
      { email: sendTo }
    );
  },
  downloadLayoutFile(idCompany, query) {
    return axios.get(
      `/api/v2/companies/${idCompany}/download_noi_layout/${query}`,
      { responseType: "arraybuffer" }
    );
  }
};
const payrollReceipts = {
  company: {
    sendReport(email) {
      return axios.post(`/api/v1/payroll_receipts/company/send_report/`, email);
    },
    getErrors() {
      return axios.get(`/api/v1/payroll_receipts/company/count/`);
    },
    getReceiptsBySearch(hasReceiver, page, name) {
      return axios.get(`/api/v1/payroll_receipts/company/?has_receiver=${hasReceiver}&paginate=true&employee_full_name=${name}&page=${page}`);
    },
    getPayrollReceipts(hasReceiver) {
      return axios.get(`/api/v1/payroll_receipts/company/?has_receiver=${hasReceiver}&paginate=true`);
    },
    getPayrollReceiptsByPage(hasReceiver, params) {
      return axios.get(`/api/v1/payroll_receipts/company/?has_receiver=${hasReceiver}&paginate=true&employee_full_name=${params.search}&page=${params.goTo}${params.filters}`);
    },
    getPayrollReceiptsByPeriod(hasReceiver, data) {
      return axios.get(`/api/v1/payroll_receipts/company/?has_receiver=${hasReceiver}&paginate=true&employee_full_name=${data.search}&issuing_date_gte=${data.start_date}&issuing_date_lte=${data.end_date}&page=${data.goTo}${data.filters}`);
    },
    uploadPayrollReceiptFile(payrollFile) {
      const csrftoken = getCookie("csrftoken");
      const formData = new FormData();
      formData.append("file", payrollFile, payrollFile.name);
      return axios.post("/api/v1/payroll_receipts/", formData, {
        "content-type": "multipart/form-data",
        "X-CSRFToken": csrftoken
      });
    },
    requireSignatureState(idCompany, data) {
      return axios.patch(`/api/v2/companies/${idCompany}/`, data);
    },
    remindSignature() {
      return axios.post(`/api/v1/payroll_receipts/company/remind_signature/`);
    },
    getReport() {
      return axios.get(`/api/v1/payroll_receipts/company/download_report/`, { responseType: "arraybuffer" });
    }
  },
  employee: {
    getPayrollReceipts() {
      return axios.get("/api/v1/payroll_receipts/");
    }
  },
  getPayrollReceiptsNotSended() {
    return axios.get(`/api/v1/payroll_receipts/company/?has_receiver=true&paginate=true&status=not_sent`).then(({ data }) => data);
  },
  sendNotSended() {
    return axios.post(`api/v1/payroll_receipts/company/send_payroll_receipts/`);
  }
};
const outsourcer = {
  loginRS(id) {
    return __async(this, null, function* () {
      const { data } = yield axios.post(`/api/v1/outsourcers/companies/${id}/login/`);
      setSessionTokens(data.token, data.refresh);
      updateAxiosToken();
    });
  },
  getLegalNames() {
    return axios.get(`/api/v1/outsourcers/legal_names/`);
  },
  getCompanyEmployees(paginationUrl, employeeStatus, search) {
    let endpointUrl = "api/v2/employees/public/?laid_off=";
    switch (employeeStatus) {
      case "active":
        endpointUrl += "False&invited=True";
        break;
      case "inactive":
        endpointUrl += "False&invited=False";
        break;
      case "ex":
        endpointUrl += "True";
        break;
      default:
        endpointUrl += "False";
    }
    if (paginationUrl) {
      endpointUrl += `&${paginationUrl}`;
    }
    if (search) {
      endpointUrl += `&search=${search}`;
    }
    return axios.get(endpointUrl).then((response) => response.data);
  },
  downloadFile() {
    return axios.get(
      "/api/v2/employees/public/directory_report/?laid_off=false",
      { responseType: "arraybuffer" }
    ).then(({ data }) => data);
  },
  downloadEmployeeFile(employeeId) {
    return axios.get(
      `/api/v2/employees/public/${employeeId}/download_archive/`,
      { responseType: "arraybuffer" }
    );
  },
  getEmployeeDocuments(employeeId) {
    return axios.get(`api/v2/employees/public/${employeeId}/documents/`);
  },
  getEmployeeCustomDocuments(employeeId) {
    return axios.get(`api/v2/employees/public/${employeeId}/custom_documents`);
  },
  uploadFormerEmployeeDocuments(employeeId, tag, document2) {
    const formData = new FormData();
    formData.append("tag", tag);
    formData.append("file", document2, document2.name);
    return axios.post(`api/v2/employees/public/${employeeId}/documents/`, formData, {
      headers: {
        "content-type": "multipart/form-data"
      }
    });
  },
  getIncidencesCount() {
    return axios.get("api/v2/incidences/count/");
  },
  getDirectoryEmployees(params = []) {
    return axios.get(`/api/v2/employees/directory/`, { params });
  }
};
const workyNews = {
  getNewsList() {
    return axios.get(`/api/v2/employee/news/?last=true`);
  },
  markNewsAsSeen($newsId) {
    return axios.post(`/api/v2/employee/news/${$newsId}/seen/`);
  }
};
const timeZones = {
  getTimeZones() {
    return axios.get("/api/v1/timezones/").then(({ data }) => data);
  }
};
const utils = {
  downloadFile(filePath) {
    return axios.get(filePath, { responseType: "arraybuffer" }).then(({ data }) => data);
  },
  validateToken() {
    return axios.post("/verify/");
  },
  refreshToken(refreshToken) {
    return axios.post("/refresh/", { refresh: refreshToken }).then(({ data }) => data);
  }
};
const updateProfile = {
  getCatalog() {
    return axios.get("/api/v1/profile/requests/catalog/");
  },
  sendRequestUpdate(payload) {
    return axios.post("/api/v1/profile/requests/", payload);
  },
  getRequestUpdate({ page, searchT }) {
    const pg = page ? `page=${page}&` : "";
    const search = searchT ? `name=${searchT}&` : "";
    return axios.get(`/api/v1/profile/requests/?${search}${pg}paginate=true`);
  },
  getIndividualRequestUpdate({ page, searchT, status }) {
    const pg = page ? `page=${page}&` : "";
    const search = searchT ? `name=${searchT}&` : "";
    const filter = status ? `status=${status}&` : "";
    return axios.get(`/api/v1/profile/requests/individual/?${search}${pg}${filter}paginate=true`);
  },
  getIndividualRequest() {
    return axios.get(`/api/v1/profile/individual_requests/`);
  },
  switchRequestUpdate({ id, payload }) {
    return axios.patch(`/api/v1/profile/requests/${id}/status/`, payload);
  },
  updateInfo(id, payload) {
    return axios.patch(`/api/v1/profile/individual_requests/${id}/update_text/`, payload);
  },
  updateDocs({ id, tag, fileP }) {
    const formData = new FormData();
    formData.append("file_tag", tag);
    formData.append("file", fileP, fileP.name);
    return axios.post(`/api/v1/profile/individual_requests/${id}/update_docs/`, formData, {
      headers: {
        "content-type": "multipart/form-data"
      }
    });
  },
  updateCustomDocs({ id, idDoc, fileC }) {
    const formData = new FormData();
    formData.append("company_custom_document", idDoc);
    formData.append("file", fileC, fileC.name);
    return axios.post(`/api/v1/profile/individual_requests/${id}/update_custom_docs/`, formData, {
      headers: {
        "content-type": "multipart/form-data"
      }
    });
  },
  updateDocsFormData({ id, formData }) {
    return axios.post(`/api/v1/profile/individual_requests/${id}/update_docs/`, formData, {
      headers: {
        "content-type": "multipart/form-data"
      }
    });
  },
  updateCustomDocsFormData({ id, formData }) {
    return axios.post(`/api/v1/profile/individual_requests/${id}/update_custom_docs/`, formData, {
      headers: {
        "content-type": "multipart/form-data"
      }
    });
  }
};
const locations = {
  getLocations(params = {}) {
    return axios.get("api/v2/locations/", { params }).then(({ data }) => data);
  },
  getLocation(locationId) {
    return axios.get(`api/v2/locations/${locationId}`).then(({ data }) => data);
  },
  createLocation(location) {
    return axios.post(`api/v2/locations/`, location).then(({ data }) => data);
  },
  updateLocation(location) {
    return axios.put(`api/v2/locations/${location.id}/`, location);
  },
  deleteLocation(locationID) {
    return axios.delete(`api/v2/locations/${locationID}/`);
  }
};
const zkteco = {
  getStatus() {
    return axios.get("/api/v1/integrations/zkteco/process/").then(({ data }) => data);
  },
  requestInfo() {
    return axios.post("/api/v1/integrations/zkteco/process/information_request/").then(({ data }) => data);
  },
  getIncidences(filter) {
    const query = Object.keys(filter).map((key) => `${key}=${filter[key]}`).join("&");
    return axios.get(`/api/v1/integrations/zkteco/incidences/?${query}`).then(({ data }) => data);
  },
  getReport(filter) {
    const query = Object.keys(filter).map((key) => `${key}=${filter[key]}`).join("&");
    return utils.downloadFile(`/api/v1/integrations/zkteco/incidences/download_report/?${query}`);
  },
  syncIncidences(payload) {
    return axios.post("/api/v1/integrations/zkteco/sync/", payload);
  }
};
const workyPlus = {
  downloadExcelTemplateEmployees() {
    return utils.downloadFile("/api/v1/integrations/zentric/employees/download_excel/");
  },
  uploadExcelTemplateEmployees(excelFile, progressCallback = () => {
  }) {
    const formData = new FormData();
    formData.append("file", excelFile);
    return axios.post(`/api/v1/integrations/zentric/employees/upload_excel/`, formData, {
      headers: {
        "content-type": "multipart/form-data"
      },
      onUploadProgress: progressCallback
    });
  },
  getIncompleteEmployees() {
    return axios.get(`/api/v1/integrations/zentric/employees/full_info`).then(({ data }) => data);
  },
  updateIncompleteEmployees(employeeList) {
    return axios.patch("api/v1/integrations/zentric/employees/massive_update/", employeeList).then(({ data }) => data);
  },
  employeeWithPayrolls() {
    return axios.get("/api/v1/integrations/zentric/employees/with_payrolls/?has_payroll=true").then(({ data }) => data);
  },
  saveMassivePayrollMovements(payrollMovements) {
    return axios.post("/api/v1/integrations/zentric/employees/massive_payroll_movements_creation/", payrollMovements).then(({ data }) => data);
  },
  saveMassivePayrollIMSSMovements(payrollIMSSMovements) {
    return axios.post("/api/v1/integrations/zentric/employees/massive_payroll_imss_creation/", payrollIMSSMovements).then(({ data }) => data);
  },
  saveMassiveAffiliationMovements(affiliationMovements) {
    return axios.post("/api/v1/integrations/zentric/employees/massive_afil_movs_creation/", affiliationMovements).then(({ data }) => data);
  }
};
const catalogs = {
  getMinAreas() {
    return axios.get("/api/v2/areas/?use_min=true").then(({ data }) => data);
  },
  getMinLocations() {
    return axios.get("/api/v2/locations/?use_min=true").then(({ data }) => data);
  },
  getStates() {
    return axios.get("/api/v1/catalogs/states/").then(({ data }) => data);
  },
  getPublicStates() {
    return axios.get("/api/v1/catalogs/public_states/").then(({ data }) => data);
  },
  getCountries() {
    return axios.get("/api/v1/catalogs/countries/").then(({ data }) => data);
  },
  getPublicCountries() {
    return axios.get("/api/v1/catalogs/public_countries/").then(({ data }) => data);
  },
  getPublicMunicipalities(stateId) {
    const url = stateId ? `/api/v1/catalogs/public_municipalities/?state=${stateId}` : "/api/v1/catalogs/public_municipalities/";
    return axios.get(url).then(({ data }) => data);
  },
  getPublicLocalities(municipalityId) {
    const url = municipalityId ? `/api/v1/catalogs/public_localities/?municipality=${municipalityId}` : "/api/v1/catalogs/public_localities/";
    return axios.get(url).then(({ data }) => data);
  },
  getPublicNeighborhoods(postalCodeId) {
    const url = postalCodeId ? `/api/v1/catalogs/public_neighborhoods/?postal_code=${postalCodeId}` : "/api/v1/catalogs/public_neighborhoods/";
    return axios.get(url).then(({ data }) => data);
  },
  getPostalCode(postalCodeKey, param = "key") {
    return axios.get(`/api/v1/catalogs/postal_codes/?${param}=${postalCodeKey}`).then(({ data }) => data);
  },
  getPublicPostalCode(postalCodeKey, param = "key") {
    return axios.get(`/api/v1/catalogs/public_postal_code/?${param}=${postalCodeKey}`).then(({ data }) => data);
  },
  createPublicPostalCode(postalCode) {
    return axios.post(`/api/v1/catalogs/public_postal_code/`, postalCode).then(({ data }) => data);
  },
  getNeighborhoods(postalCodeId) {
    return axios.get(`/api/v1/catalogs/neighborhoods/?postal_code=${postalCodeId}`).then(({ data }) => data);
  },
  getTaxRegimens() {
    return axios.get("/api/v1/catalogs/tax_regimes/").then(({ data }) => data);
  },
  getDelegationsIMSS() {
    return axios.get("/api/v1/catalogs/imss_delegations/").then(({ data }) => data);
  },
  getSubdelegationsIMSS() {
    return axios.get("/api/v1/catalogs/imss_subdelegations/").then(({ data }) => data);
  },
  getRiskFractions() {
    return axios.get("/api/v1/catalogs/risk_fractions/").then(({ data }) => data);
  },
  getRiskClasses() {
    return axios.get("/api/v1/catalogs/risk_classes/").then(({ data }) => data);
  },
  getBanks() {
    return axios.get("/api/v1/catalogs/banks/").then(({ data }) => data);
  },
  getPublicBanks() {
    return axios.get("/api/v1/catalogs/public_banks/").then(({ data }) => data);
  },
  getWorkdayTypes() {
    return axios.get("/api/v1/catalogs/workday_types/").then(({ data }) => data);
  },
  getVacationPolicies() {
    return axios.get("/api/v2/vacations/company/policies/?policy_kind=VAC").then(({ data }) => data);
  },
  getPtoPolicies() {
    return axios.get("/api/v2/vacations/company/policies/?policy_kind=PTO").then(({ data }) => data);
  },
  getMinPositions() {
    return axios.get("/api/v2/work_positions/?use_min=true").then(({ data }) => data);
  }
};
const structures = {
  getLegalNames() {
    return axios.get("api/v2/razones_sociales/").then(({ data }) => data);
  },
  createLegalName(legalName) {
    return axios.post("/api/v2/razones_sociales/", legalName).then(({ data }) => data);
  },
  updateLegalName(legalName) {
    return axios.put(`/api/v2/razones_sociales/${legalName.id}/`, legalName).then(({ data }) => data);
  },
  deleteLegalName(legalName) {
    return axios.delete(`/api/v2/razones_sociales/${legalName.id}/`).then(({ data }) => data);
  },
  getEmployerRegistrations() {
    return axios.get("/api/v2/employer_registries/");
  },
  createEmployerRegistration(employerRegistration) {
    return axios.post("/api/v2/employer_registries/", employerRegistration).then(({ data }) => data);
  },
  updateEmployerRegistration(employerRegistration) {
    return axios.put(`/api/v2/employer_registries/${employerRegistration.id}/`, employerRegistration).then(({ data }) => data);
  },
  deleteEmployerRegistration(employerRegistrationId) {
    return axios.delete(`/api/v2/employer_registries/${employerRegistrationId}/`);
  },
  createRiskPremium(riskPremium) {
    return axios.post("/api/v2/risk_premiums/", riskPremium).then(({ data }) => data);
  },
  updateRiskPremium(riskPremium) {
    return axios.put(`/api/v2/risk_premiums/${riskPremium.id}/`, riskPremium).then(({ data }) => data);
  },
  deleteRiskPremium(riskPremiumId) {
    return axios.delete(`/api/v2/risk_premiums/${riskPremiumId}/`);
  }
};
const areas = {
  getAreas() {
    return axios.get("/api/v2/areas/?use_min=true").then(({ data }) => data);
  },
  createNewArea(data) {
    return axios.post(`/api/v2/areas/`, data);
  },
  editArea(id, data) {
    return axios.put(`/api/v2/areas/${id}/`, data);
  },
  deleteArea(id) {
    return axios.delete(`/api/v2/areas/${id}/`);
  }
};
const positions = {
  getWorkPuestos() {
    return axios.get("/api/v2/work_puestos/");
  },
  getPositions() {
    return axios.get("/api/v2/work_positions/").then(({ data }) => data);
  },
  getPositionsFlat() {
    return axios.get("/api/v2/work_positions/flat/");
  },
  createNewPosition(data) {
    return axios.post(`/api/v2/work_positions/`, data);
  },
  editPosition(id, data) {
    return axios.put(`/api/v2/work_positions/${id}/`, data);
  },
  deletePosition(id) {
    return axios.delete(`/api/v2/work_positions/${id}/`);
  }
};
const reports = {
  getEmployeeReports(companyId) {
    return axios.get(`/api/v2/companies/${companyId}/report_employees_by_month/`).then(({ data }) => data);
  },
  getEmployeeGenderReport(companyId) {
    return axios.get(`/api/v2/companies/${companyId}/report_gender/`).then(({ data }) => data);
  },
  getEmployeeAgeReport(companyId) {
    return axios.get(`/api/v2/companies/reports/${companyId}/report_age/`).then(({ data }) => data);
  },
  getEmployeesEducationReport(companyId) {
    return axios.get(`/api/v2/companies/${companyId}/report_education_level/`).then(({ data }) => data);
  },
  getEmployeeCivilStatusReport(companyId) {
    return axios.get(`/api/v2/companies/${companyId}/report_civil_status/`).then(({ data }) => data);
  },
  getEmployeeOldReport(companyId) {
    return axios.get(`/api/v2/companies/${companyId}/report_date_of_employment/`).then(({ data }) => data);
  },
  getPayrollReport(companyId) {
    return axios.get(`/api/v2/companies/${companyId}/report_salary/`).then(({ data }) => data);
  },
  getIncidencesReport(companyId) {
    return axios.get(`/api/v2/companies/reports/${companyId}/incidences/`).then(({ data }) => data);
  },
  getAbsencesReport(companyId) {
    return axios.get(`/api/v2/companies/reports/${companyId}/report_abscenses_by_area/`).then(({ data }) => data);
  },
  getRotationReport(companyId) {
    return axios.get(`/api/v2/companies/${companyId}/report_trimester_rotation/`).then(({ data }) => data);
  },
  getPacoAppToken() {
    return axios.post("/api/v1/integrations/pacoapp/dashboard_token/").then(({ data }) => data);
  }
};
const organization = {
  getCurrentOrganization() {
    return axios.get("/api/v1/organization_company/current_company/").then(({ data }) => data);
  }
};
const backoffice = {
  getEmployeesFromCompany({ id: companyId }) {
    return axios.get(`/api/v2/companies/${companyId}/employees/?organization_users=true`).then(({ data }) => data);
  },
  getCompanyInfo(companyId) {
    return axios.get(`/api/v2/companies/${companyId}/`).then(({ data }) => data);
  },
  getOrganizations() {
    return axios.get(`/api/v1/organizations/`).then(({ data }) => data);
  }
};
const incidences = {
  getCountIncidences(companyId) {
    const query = {
      paginate: true,
      page_size: 1,
      status: "PEN",
      time_clock: false
    };
    return axios.get(`api/v2/companies/${companyId}/incidences/`, { params: query });
  }
};
const modules = {
  getModuleTabs() {
    return axios.get("/api/v2/module_tabs/").then(({ data }) => data);
  },
  updateModuleTab(moduleTab) {
    return axios.put(`/api/v2/module_tabs/${moduleTab.id}/`, moduleTab);
  }
};
const profileConfig = {
  getPublicSections() {
    return axios.get("/api/v2/public_sections/").then(({ data }) => data);
  },
  updatePublicSections(configuration) {
    return axios.put(`/api/v2/public_sections/${configuration.id}/`, configuration);
  }
};
const onboarding = {
  saveEmployeeOnboarding(fields = [], employeeId) {
    const formData = new FormData();
    fields.forEach((field) => {
      Object.keys(field).forEach((key) => {
        formData.append(`[${field.preonboarding_document}][${key}]`, field[key] || null);
      });
    });
    return axios.post(`api/v1/preonboardings/candidate/${employeeId}/save_documents/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }
};
export const apiGet = (url, query = "", options) => axios.get(`${url}${query ? `?${query}` : ""}`, options).then(({ data }) => data);
export const apiPost = (url, body) => axios.post(url, body).then(({ data }) => data);
export const apiPut = (url, body) => axios.put(url, body).then(({ data }) => data);
export const apiDelete = (url, body) => axios.delete(url, body).then(({ data }) => data);
export const api = {
  calendar,
  announcements,
  backdoor,
  switchCompany,
  company,
  customizedData,
  employees,
  download,
  downloadFiltered,
  vacations,
  vacationsV3,
  candidates,
  comments,
  incidencias,
  encuestas,
  getCompanyIncidenciasSettings,
  updateCompanyIncidenciasSettings,
  me,
  meEmployee,
  getOrgTree,
  getOrgTreeSVG,
  getUsers,
  getUser,
  inviteUser,
  setUserRole,
  setUserPassword,
  verifyUser,
  resetPassword,
  magicLink,
  // internal login >s
  login,
  logout,
  getTokens,
  profile,
  updateAxiosToken,
  getNewToken,
  // < interal login
  timeOff,
  timeClock,
  file,
  customIncidences,
  massiveIncidences,
  levels,
  businessUnits,
  editRequest,
  payments,
  dashboard,
  directory,
  outsourcer,
  trainings,
  benefits,
  bulk,
  plans,
  payrollReceipts,
  evaluations,
  approvalFlows,
  positions,
  permissions,
  workyNews,
  massiveEmployees,
  tags,
  timeZones,
  prepayroll,
  utils,
  updateProfile,
  internalProcess,
  zentric,
  locations,
  delegatePermissions,
  zkteco,
  vacancies,
  workyPlus,
  catalogs,
  structures,
  areas,
  upsell,
  reports,
  organization,
  backoffice,
  incidences,
  modules,
  profileConfig,
  digitalSign,
  daysOff,
  company,
  onboarding
};
export default function plugin(Vue) {
  if (plugin.installed) {
    return;
  }
  plugin.installed = true;
  Vue.prototype.$api = api;
}
export {
  me,
  candidates,
  download,
  calendar,
  locations,
  zkteco,
  timeClock,
  vacancies,
  comments,
  workyPlus,
  delegatePermissions,
  catalogs,
  directory,
  structures,
  zentric,
  reports,
  organization,
  backoffice,
  incidences,
  utils,
  modules,
  profileConfig,
  documentEditor,
  digitalSign,
  benefits,
  daysOff,
  company,
  onboarding
};
